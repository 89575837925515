export const GET_USER_PROFILE_START = 'GET_USER_PROFILE_START';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAIL = 'GET_USER_PROFILE_FAIL';
export const GET_USER_PROFILE = 'GET_USER_PROFILE';

export const ON_SAVE_USER_PROFILE_INFO_START =
    'ON_SAVE_USER_PROFILE_INFO_START';
export const ON_SAVE_USER_PROFILE_INFO_SUCCESS =
    'ON_SAVE_USER_PROFILE_INFO_SUCCESS';
export const ON_SAVE_USER_PROFILE_INFO_FAIL = 'ON_SAVE_USER_PROFILE_INFO_FAIL';
export const ON_SAVE_USER_PROFILE_INFO = 'ON_SAVE_USER_PROFILE_INFO';

export const ON_SAVE_USER_PROFILE_PICTURE_START =
    'ON_SAVE_USER_PROFILE_PICTURE_START';
export const ON_SAVE_USER_PROFILE_PICTURE_SUCCESS =
    'ON_SAVE_USER_PROFILE_PICTURE_SUCCESS';
export const ON_SAVE_USER_PROFILE_PICTURE_FAIL =
    'ON_SAVE_USER_PROFILE_PICTURE_FAIL';
export const ON_SAVE_USER_PROFILE_PICTURE = 'ON_SAVE_USER_PROFILE_PICTURE';

export const ON_GET_CERTIFICATE_FOR_USER_PRESENTATION_START =
    'ON_GET_CERTIFICATE_FOR_USER_PRESENTATION_START';
export const ON_GET_CERTIFICATE_FOR_USER_PRESENTATION_SUCCESS =
    'ON_GET_CERTIFICATE_FOR_USER_PRESENTATION_SUCCESS';
export const ON_GET_CERTIFICATE_FOR_USER_PRESENTATION_FAIL =
    'ON_GET_CERTIFICATE_FOR_USER_PRESENTATION_FAIL';
export const ON_GET_CERTIFICATE_FOR_USER_PRESENTATION_RESET =
    'ON_GET_CERTIFICATE_FOR_USER_PRESENTATION_RESET';
