import React from 'react';
import { Grid } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { FaCheckCircle } from 'react-icons/fa';
import { IoCloseCircle } from 'react-icons/io5';
import { MdDeleteForever } from 'react-icons/md';
import styles from './Presentations.module.css';
import { GridOverlay } from '@material-ui/data-grid';
import { commonUTCDateFormat } from 'shared/utils/date';
import { v4 as uuidv4 } from 'uuid';
import { API } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { RingLoader } from 'components/shared';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import defaultPresentationThumbnailImage from '../../../images/sample/presentation/presentation-hero-image.png';
const presentationThumbnailImage = (id) =>
    `${process.env.REACT_APP_PRESENTATION_THUMBNAILS_URL}/thumbnails/presentations/${id}/thumbnail.jpeg`;

const useStyles = makeStyles((theme) => ({
    root: {
        borderBottom: 'none',
        '& .MuiDataGrid-root': {
            border: 0
        },
        '& .MuiDataGrid-footer': {
            justifyContent: 'center'
        },
        '& .MuiDataGrid-colCellTitle': {
            color: '#283E59',
            fontWeight: 300,
            fontSize: 12
        },
        '& .MuiDataGrid-colCellTitleContainer': {
            justifyContent: 'center'
        },
        '& .MuiDataGrid-cellWithRenderer': {
            justifyContent: 'center'
        },
        '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
            outline: 'none !important'
        },
        '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus': {
            outline: 'none !important'
        },
        '& .MuiDataGrid-overlay': {
            backgroundColor: '#fff',
            zIndex: 1
        }
    },
    materialSelect: {
        '& .MuiOutlinedInput-input': {
            padding: '10.5px 14px'
        }
    }
}));

const postPublishPresentation = async (id) => {
    console.log(id);
    return await API.post(
        process.env.REACT_APP_API_ENDPOINT_NAME,
        `/admin/presentation/${id}/publish`
    ).then((response) => {
        return response;
    });
};

const removePresentation = async (id) => {
    return await API.post(
        process.env.REACT_APP_API_ENDPOINT_NAME,
        `/admin/presentation/${id}/remove`
    ).then((response) => {
        return response;
    });
};

const fetchPresentations = async () => {
    return await API.get(process.env.REACT_APP_API_ENDPOINT_NAME, '/admin/presentations').then(
        (response) => {
            const remap = response
                ? response.map(({ pid: id, ...value }) => ({
                      id,
                      ...value
                  }))
                : [];
            const sortedArray = remap.sort(function (a, b) {
                return new Date(b.createdDate) - new Date(a.createdDate);
            });

            return sortedArray;
        }
    );
};

const Presentations = (props) => {
    const classes = useStyles();

    const queryClient = useQueryClient();

    const { isLoading, error, data, isFetching } = useQuery({
        queryKey: ['presentations'],
        queryFn: fetchPresentations,
        staleTime: 60000
    });

    const publishPresentationMutation = useMutation({
        mutationFn: postPublishPresentation,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['presentations'] });
        }
    });

    const removePresentationMutation = useMutation({
        mutationFn: removePresentation,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['presentations'] });
        }
    });

    const columns = [
        {
            field: 'id',
            hide: true
        },
        {
            field: 'title',
            headerName: 'Title',
            width: 500,
            renderCell: (params) => (
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    style={{ padding: 5 }}
                    alignItems="center">
                    <Grid item lg={3}>
                        <div className={styles.DatagridPresentationImage}>
                            <img
                                src={presentationThumbnailImage(params.row.id)}
                                onError={(e) => {
                                    e.target.onError = null;
                                    e.target.src = defaultPresentationThumbnailImage;
                                }}
                                alt="Datagrid Display"></img>
                        </div>
                    </Grid>
                    <Grid item lg={9}>
                        <Box component="div">
                            <span
                                className={`${styles.PresentationsColumnSpan} ${styles.PresentationsColumnSpanTitle}`}>
                                {params.row.title}
                            </span>
                        </Box>
                    </Grid>
                </Grid>
            ),
            disableColumnMenu: true,
            disableColumnFilter: true,
            sortable: false
        },
        {
            field: 'createDate',
            headerName: 'Date',
            width: 150,
            renderCell: (params) => (
                <div className={styles.Reviews}>
                    <span className={styles.ReviewsDetails}>
                        {params.row.createdDate ? commonUTCDateFormat(params.row.createdDate) : '-'}
                    </span>
                </div>
            ),
            disableColumnMenu: true,
            disableColumnFilter: true,
            sortable: false
        },
        {
            field: 'duration',
            headerName: 'Duration',
            width: 150,
            renderCell: (params) => (
                <span className={styles.DurationSpan}>
                    {params.row.videoDuration ? params.row.videoDuration.slice(0, 5) : '-'}
                </span>
            ),
            disableColumnMenu: true,
            disableColumnFilter: true,
            sortable: false
        },
        {
            field: 'ceApproved',
            headerName: 'CE Approved',
            width: 150,
            renderCell: (params) => (
                <Link to={`/presentation/${params.row.id}`}>
                    <span className={styles.ViewsSpan}>
                        {params.row.approvedCE ? (
                            <FaCheckCircle size={'1.8em'} color={'#34C647'} />
                        ) : (
                            <IoCloseCircle size={'1.8em'} color={'#F12B2C'} />
                        )}
                    </span>
                </Link>
            ),
            disableColumnMenu: true,
            disableColumnFilter: true,
            sortable: false
        },
        {
            field: 'status',
            headerName: 'Published',
            width: 150,
            renderCell: (params) => (
                <button
                    onClick={() => {
                        if (params.row.status !== 'INDEX')
                            publishPresentationMutation.mutate(params.row.id);
                    }}>
                    {params.row.status === 'INDEX' ? (
                        <FaCheckCircle size={'1.8em'} color={'#34C647'} />
                    ) : (
                        <IoCloseCircle size={'1.8em'} color={'#F12B2C'} />
                    )}
                </button>
            ),
            disableColumnMenu: true,
            disableColumnFilter: true,
            sortable: false
        },
        {
            field: 'deleteAction',
            headerName: 'Delete',
            width: 150,
            renderCell: (params) => (
                <button
                    className={styles.ViewsSpan}
                    onClick={() => removePresentationMutation.mutate(params.row.id)}>
                    <MdDeleteForever size={'1.8em'} color={'#7F7F7F'} />
                </button>
            ),
            disableColumnMenu: true,
            disableColumnFilter: true,
            sortable: false
        }
    ];

    const CustomLoadingOverlay = () => {
        return (
            <GridOverlay>
                <RingLoader />
            </GridOverlay>
        );
    };

    const CustomNoRowsOverlay = () => {
        const classes = useStyles();

        return (
            <GridOverlay className={classes.root}>
                {isLoading || isFetching ? (
                    <RingLoader />
                ) : (
                    <div className="-lg:px-8 -md:px-4 container flex flex-col items-center justify-center max-w-screen-lg mx-auto">
                        <img
                            className={`my-12`}
                            src={require('../../../images/sample/presentation/Standing.png')}
                            alt="No Result User"
                        />

                        <p className={`${styles.NoResultMessage}`}>
                            You currently have no
                            <br />
                            modules
                        </p>
                    </div>
                )}
            </GridOverlay>
        );
    };

    const DataTable = (props) => {
        const rows = props.rows || [];

        return (
            <div style={{ height: 560, width: '100%' }} className={classes.root}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    rowHeight={75}
                    pageSize={6}
                    autoPageSize
                    showCellRightBorder={false}
                    showColumnRightBorder={false}
                    disableSelectionOnClick
                    components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                        LoadingOverlay: CustomLoadingOverlay
                    }}
                    loading={
                        isLoading ||
                        isFetching ||
                        removePresentationMutation.isLoading ||
                        publishPresentationMutation.isLoading
                    }
                />
            </div>
        );
    };

    // const onViewPresentation = (id) => {
    //     history.push('/visualpresentations/' + id);
    // }

    // const onEditPresentation = (id, videoTitle, description) => {
    //     history.push({
    //         pathname: '/presentation/edit/' + id,
    //         search: "?" + new URLSearchParams({ videoTitle, description }).toString()
    //     });
    // }

    // const viewsReportDurations = [{ label: 'Most Views', value: 'mostViews' }];

    return (
        <Grid container direction="row" justifyContent="center" className="mt-12">
            <Grid item lg={12}>
                <p className={styles.Title}>Presentations</p>
            </Grid>

            <Grid item lg={12} className={`${styles.PresentationsContainer}`}>
                <Grid container direction="row" justifyContent="space-between">
                    <Grid item lg={8}></Grid>
                    {/*<Grid item lg={4} style={{ textAlign: 'end' }}>
                        <FormControl
                            variant="outlined"
                            style={{ width: '50%' }}>
                            <Select
                                native
                                // value={state.age}
                                // onChange={handleChange}
                                className={`${styles.PresentationsSelect} ${classes.materialSelect}`}
                                inputProps={{
                                    name: 'viewsReportDurations',
                                    id: 'outlined-age-native-simple'
                                }}
                                style={{
                                    color: '#283E59',
                                    fontSize: '18px',
                                    fontWeight: 600
                                }}>
                                {viewsReportDurations.map(
                                    (viewsReportDuration, index) => (
                                        <option
                                            key={index}
                                            value={viewsReportDuration.value}>
                                            {viewsReportDuration.label}
                                        </option>
                                    )
                                )}
                            </Select>
                        </FormControl>
                                    </Grid>*/}

                    <Grid item lg={12} style={{ marginTop: 10 }}>
                        <DataTable rows={data} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Presentations;
