import React, { useEffect, useState } from 'react';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { Analytics } from 'aws-amplify';
import styles from './ViewPresentation.module.css';
import { PDFView } from 'components/shared';
import { VideoPlayer } from 'components/shared';
import { useHistory, useParams } from 'react-router';
import { RingLoader } from 'components/shared';
import ReactModalAdapter from 'helpers/ReactModalAdapter';
import styled from 'styled-components';
import tw from 'twin.macro';
import { FaRegCheckCircle } from 'react-icons/fa';
import { FaRegTimesCircle } from 'react-icons/fa';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import StarRatings from 'react-star-ratings';
import _ from 'lodash';
import { checkValidity } from 'shared/utils/formValidity';
import Topbar from 'components/headers/Topbar/Topbar';
import Footer from 'components/footers/Footer.js';
import { useDispatch, useSelector } from 'react-redux';
import { getPresentation } from 'state/actions';
import { submitPresentationFeedback } from 'state/actions';
import { submitPresentationFeedbackReset } from 'state/actions';
import { getPresentationReset } from 'state/actions';
import { isNetworkOnline } from 'shared/utils/Network';
import { NoResult } from 'components/shared/UI';
import { useUser } from 'util/auth.js';
import { useAtom } from 'jotai';
import { courseModules } from '../PresentationPage';
import { useMutation } from '@tanstack/react-query';
import { API } from 'aws-amplify';

const StyledModal = styled(ReactModalAdapter)`
    &.ViewPresentationModal__overlay {
        ${tw`fixed inset-0 z-50`}
        transition: opacity 2000ms ease-in-out;
        background: rgba(0, 0, 0, 0.3);
    }
    &.ViewPresentationModal__content {
        ${tw`xl:mx-auto sm:m-auto justify-self-start sm:w-1/2 absolute inset-0 flex items-start items-center self-start justify-center w-full max-w-screen-xl my-auto bg-white rounded-lg outline-none`}
        min-height: 50%;
        height: fit-content;
    }
    .content {
        ${tw`lg:p-16 w-full`}
    }
`;

const StyledLargeModal = styled(ReactModalAdapter)`
    &.ViewPresentationModal__overlay {
        ${tw`fixed inset-0 z-50`}
        transition: opacity 2000ms ease-in-out;
        background: rgba(0, 0, 0, 0.3);
    }
    &.ViewPresentationModal__content {
        ${tw`xl:mx-auto sm:m-auto max-h-3/4 justify-self-start sm:w-1/2 absolute inset-0 flex items-start items-center self-start justify-center w-full max-w-screen-xl m-auto my-auto bg-white rounded-lg outline-none`}
    }
    .content {
        ${tw`lg:p-16 w-full`}
    }
`;

const updateCourseProgress = async (postData) => {
    return await API.post(
        process.env.REACT_APP_API_ENDPOINT_NAME,
        '/course/progress',
        postData
    ).then((response) => {
        return response;
    });
};

const ViewPresentation = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { user } = useUser();
    const [currentPage, setCurrentPage] = useState(1);
    const [numPages, setNumPages] = useState(0);
    const [isDocumentLoaded, setIsDocumentLoaded] = useState(false);
    const [isVideoEnded, setIsVideoEnded] = useState(false);
    const [isEvaluationEnabled, setIsEvaluationEnabled] = useState(false);
    const [isWelcomeModalActive, setIsWelcomeModalActive] = useState(false);
    const [isOnFinishModalActive, setIsOnFinishModalActive] = useState(false);
    const [isExitModalActive, setIsExitModalActive] = useState(false);
    const [isEvaluateModalActive, setIsEvaluateModalActive] = useState(false);
    const [isVideoReady, setIsVideoReady] = useState(false);
    const [modules, setModules] = useAtom(courseModules);
    const [lastModule, setLastModule] = useState(false);
    const currentPresentationState = useSelector(
        ({ presentations }) => presentations.currentPresentationToView
    );
    const submitPresentationFeedbackState = useSelector(
        ({ presentations }) => presentations.submitPresentationFeedback
    );

    const [evaluateForm, setEvaluateForm] = useState({
        controls: [
            {
                label: 'Accuracy',
                key: 'accuracy'
            },
            {
                label: 'Currency',
                key: 'currency'
            },
            {
                label: 'Engaging',
                key: 'engaging'
            },
            {
                label: 'Enthusiastic',
                key: 'enthusiasm'
            },
            {
                label: 'Knowledgeable',
                key: 'knowledge'
            },
            {
                label: 'Comment',
                key: 'comment',
                valid: false,
                validation: {
                    required: true
                },
                touched: false,
                canUpdate: true,
                errors: [],
                show: true
            }
        ],
        data: {
            stars: {
                accuracy: 0,
                currency: 0,
                engaging: 0,
                enthusiasm: 0,
                knowledge: 0
            },
            comment: ''
        },
        isValid: false
    });

    const { id, courseId } = useParams();
    const [data, setData] = useState({
        pdfData: { url: '' },
        recordedVideo: { url: '' },
        timestamps: [],
        isVideoOnlyPresentation: false,
        isVideoEmbed: false
    });

    const feedbackRatingLabels = [
        {
            label: 'Accuracy',
            name: 'accuracy'
        },
        {
            label: 'Currency',
            name: 'currency'
        },
        {
            label: 'Engaging',
            name: 'engaging'
        },
        {
            label: 'Enthusiastic',
            name: 'enthusiasm'
        },
        {
            label: 'Knowledgeable',
            name: 'knowledge'
        }
    ];

    const postMutation = useMutation(updateCourseProgress, {
        onSuccess: (data) => {}
    });

    useEffect(() => {
        if (courseId) {
            if (!modules) {
                history.push(`/presentation/${courseId}`);
            }
        }
    }, [courseId]);

    useEffect(() => {
        dispatch(getPresentation(id));
        dispatch(submitPresentationFeedbackReset());

        if (user.attributes && user.attributes.zoneinfo) {
            setIsWelcomeModalActive(false);
        } else {
            setIsWelcomeModalActive(true);
        }

        if (modules) {
            const modKeys = Object.keys(modules);
            const keyLength = modKeys.length - 1;
            if (modules[modKeys[keyLength]] === id) {
                setIsEvaluateModalActive(false);
                setIsEvaluationEnabled(false);
                setLastModule(true);
            }
        }

        return () => {
            dispatch(getPresentationReset());
        };
    }, [id]);

    useEffect(() => {
        const { success, data } = currentPresentationState;
        if (success && data) {
            const { pdf_url, video_url, timestamps, videoOnly = false } = data;
            setData((state) => ({
                ...state,
                timestamps,
                pdfData: {
                    ...state.pdfData,
                    url: pdf_url
                },
                recordedVideo: {
                    ...state.recordedVideo,
                    url: video_url
                },
                isVideoOnlyPresentation: videoOnly,
                isVideoEmbed: video_url.indexOf('vimeo.com') > -1
            }));
        }
    }, [currentPresentationState]);

    const confirmExitToDashboard = () => {
        setIsExitModalActive(false);
        history.push('/user/dashboard');
    };

    const goToNextModule = () => {
        if (modules) {
            let next;
            const keys = Object.keys(modules);
            for (let i = 0; i < keys.length; i++) {
                if (modules[keys[i]] === id) {
                    next = modules[keys[i + 1]];
                }
            }
            setIsOnFinishModalActive(false);
            history.push(`/course/${courseId}/module/view/${next}`);
        }
    };

    const onExit = () => {
        setIsExitModalActive(true);
    };

    const onChangeFeedbackRating = (newRating, name) => {
        let updatedEvaluateForm = _.cloneDeep(evaluateForm);
        updatedEvaluateForm.data.stars[name] = newRating;
        setEvaluateForm(updatedEvaluateForm);
    };

    const onChangeEvaluateFormInputHandler = (event, controlName) => {
        const updatedControls = _.cloneDeep(evaluateForm.controls);
        let updatedDate = { ...evaluateForm.data };
        for (let i = 0; i < updatedControls.length; i++) {
            if (updatedControls[i].key === controlName) {
                let value = null;
                value = event.target.value;
                updatedControls[i] = {
                    ...updatedControls[i],
                    valid: checkValidity(
                        value,
                        updatedControls[i].validation,
                        updatedControls[i].errors
                    ),
                    touched: true
                };
                updatedDate = {
                    ...updatedDate,
                    [updatedControls[i].key]: value
                };
            }
        }
        let isFormValid = false;
        isFormValid = updatedControls.every((control) => {
            if (control.show) return control.valid === true;
            return true;
        });
        let updatedEvaluateForm = {
            ...evaluateForm,
            controls: updatedControls,
            data: updatedDate,
            isValid: isFormValid
        };
        setEvaluateForm(updatedEvaluateForm);
    };

    const onSubmitEvaluateForm = () => {
        dispatch(submitPresentationFeedback(id, evaluateForm.data));
    };

    const welcomeModal = (
        <StyledModal
            closeTimeoutMS={300}
            className="ViewPresentationModal"
            isOpen={isWelcomeModalActive}
            onRequestClose={() => {
                setIsWelcomeModalActive(false);
            }}
            shouldCloseOnOverlayClick={true}>
            <div className="sm:px-16 flex flex-col justify-center min-h-full px-8">
                <div className="justify-center w-full mt-5 text-center">
                    <h2
                        className={`font-semibold text-4xl ${styles.WelcomeModalHeader}`}>
                        Welcome!
                    </h2>
                </div>

                <div className="justify-center w-full mt-5 text-center">
                    <p
                        className={`font-normal text-xl ${styles.WelcomeModalPara}`}>
                        Upon completion, you will be directed to the next module
                        or certificate. If you leave the module, you pick up
                        your course at the start of the current module. Once you
                        fill out the evaluation, you will be awarded your
                        certificate
                    </p>
                </div>

                <div className="justify-center w-full mt-5 text-center">
                    <button
                        className={`inline-block rounded py-1 px-8 mb-4 sm:px-16 font-bold text-lg text-white mr-5 outline-none ${styles.Button} ${styles.ButtonActive}`}
                        onClick={() => {
                            setIsWelcomeModalActive(false);
                        }}>
                        Done
                    </button>
                </div>
            </div>
        </StyledModal>
    );

    const onFinishModal = (
        <StyledModal
            closeTimeoutMS={300}
            className="ViewPresentationModal"
            isOpen={isOnFinishModalActive}
            onRequestClose={() => {
                setIsOnFinishModalActive(false);
            }}
            shouldCloseOnOverlayClick={!modules || lastModule}>
            {!modules || lastModule ? (
                <div className="absolute top-0 left-0 flex justify-end w-full p-4">
                    <IoMdCloseCircleOutline
                        className="cursor-pointer"
                        size={'2em'}
                        color={'#283E59'}
                        onClick={() => {
                            setIsOnFinishModalActive(false);
                        }}
                    />
                </div>
            ) : null}

            <div className="flex flex-col self-center justify-center w-full min-h-full px-4">
                <div className="flex items-center justify-center w-full mt-5 text-center">
                    <FaRegCheckCircle size={'6em'} color={'#F9B041'} />
                </div>

                <div className="justify-center w-full mt-5 text-center">
                    <h2
                        className={`font-semibold text-4xl ${styles.OnFinishModalHeader}`}>
                        Well Done!
                    </h2>
                </div>

                <div className="justify-center w-full mt-5 text-center">
                    <p
                        className={`font-normal text-xl ${styles.OnFinishModalPara}`}>
                        You have completed this module
                    </p>
                </div>

                {modules && !lastModule ? (
                    <div className="sm:mt-5 justify-center w-full my-5 text-center">
                        <button
                            className={`inline-block rounded py-1 px-8 sm:px-16 font-bold text-lg text-white mr-5 outline-none ${styles.Button} ${styles.ButtonActive}`}
                            onClick={() => {
                                goToNextModule();
                            }}>
                            Next Module
                        </button>
                    </div>
                ) : (
                    <div className="sm:mt-5 justify-center w-full my-5 text-center">
                        <button
                            className={`inline-block rounded py-1 px-8 sm:px-16 font-bold text-lg text-white mr-5 outline-none ${styles.Button} ${styles.ButtonActive}`}
                            onClick={() => {
                                setIsOnFinishModalActive(false);
                                setIsEvaluateModalActive(true);
                            }}>
                            Evaluate
                        </button>
                    </div>
                )}
            </div>
        </StyledModal>
    );

    const onExitModal = (
        <StyledLargeModal
            closeTimeoutMS={0}
            className="ViewPresentationModal"
            isOpen={isExitModalActive}
            onRequestClose={() => {
                setIsExitModalActive(false);
            }}
            shouldCloseOnOverlayClick={true}>
            <div className="absolute top-0 left-0 flex justify-end w-full p-4">
                <IoMdCloseCircleOutline
                    className="cursor-pointer"
                    size={'2em'}
                    color={'#283E59'}
                    onClick={() => {
                        setIsExitModalActive(false);
                    }}
                />
            </div>

            <div className="flex flex-col self-center justify-center w-full px-16">
                <div className="flex items-center justify-center w-full mt-5 text-center">
                    <img
                        height="100%"
                        className="mr-24"
                        src={require('pages/assets/img/exit-confirm-image.png')}></img>
                </div>

                <div className="justify-center w-full mt-2 text-center">
                    <h2
                        className={`font-semibold text-2xl sm:text-4xl ${styles.OnExitModalHeader}`}>
                        Are you sure...
                    </h2>
                </div>

                <div className="justify-center w-full mt-3 text-center">
                    <p
                        className={`font-normal text-lg sm:text-xl ${styles.OnExitModalPara}`}>
                        Pushing continue below will omit any credit and take you
                        back to your dashboard
                    </p>
                </div>

                <div className="justify-center w-full mt-5 text-center">
                    <button
                        className={`inline-block rounded py-1 px-8 sm:px-16 font-bold text-lg text-white mr-5 outline-none ${styles.Button} ${styles.ButtonActive}`}
                        onClick={() => {
                            confirmExitToDashboard();
                        }}>
                        Continue
                    </button>
                </div>
            </div>
        </StyledLargeModal>
    );

    const onEvaluateModal = () => {
        let contentUI = (
            <div className="flex flex-col self-center justify-center w-full px-16">
                <div className="w-full mt-2 text-left">
                    <h2
                        className={`font-medium text-xl ${styles.EvaluateModalHeader}`}>
                        Leave a review
                    </h2>
                </div>

                <div className="w-full mt-2 text-left">
                    <p
                        className={`font-normal text-lg ${styles.EvaluateModalPara}`}>
                        To claim CE credits leave a review for this presentation
                    </p>
                </div>

                <div className="justify-center w-full mt-3 text-center">
                    <ul>
                        {feedbackRatingLabels.map(
                            (ratingLabel, ratingLabelIndex) => (
                                <li
                                    className={`flex justify-between`}
                                    key={ratingLabelIndex}>
                                    <span
                                        className={`font-semibold text-base ${styles.EvaluateModalPara}`}>
                                        {ratingLabel.label}
                                    </span>

                                    <StarRatings
                                        starDimension="18px"
                                        starSpacing="2px"
                                        rating={
                                            evaluateForm.data.stars[
                                                ratingLabel.name
                                            ]
                                        }
                                        starRatedColor="orange"
                                        starHoverColor="orange"
                                        numberOfStars={5}
                                        changeRating={onChangeFeedbackRating}
                                        name={ratingLabel.name}
                                    />
                                </li>
                            )
                        )}
                    </ul>
                </div>

                <div className="w-full mt-2 text-left">
                    <h2
                        className={`font-medium text-xl ${styles.EvaluateModalHeader}`}>
                        Comments
                    </h2>
                </div>
                <div className="w-full">
                    <TextareaAutosize
                        className={`w-full leading-loose ${styles.EvaluateModalCommentInput}`}
                        value={evaluateForm.data['comment']}
                        placeholder={'Type your comment here...'}
                        minRows={4}
                        maxRows={4}
                        onChange={(inputEvent) =>
                            onChangeEvaluateFormInputHandler(
                                inputEvent,
                                'comment'
                            )
                        }
                    />
                </div>

                <div className="justify-center w-full my-5 text-center">
                    <button
                        className={`inline-block rounded py-1 sm:px-16 font-bold text-lg text-white mr-5 outline-none ${
                            styles.Button
                        } ${evaluateForm.isValid ? styles.ButtonActive : ''}`}
                        disabled={!evaluateForm.isValid}
                        onClick={() => {
                            onSubmitEvaluateForm();
                        }}>
                        Submit
                    </button>
                </div>
            </div>
        );
        if (submitPresentationFeedbackState.loading) {
            contentUI = (
                <div className="flex items-center justify-center h-full">
                    <RingLoader />
                </div>
            );
        }
        if (
            !submitPresentationFeedbackState.loading &&
            submitPresentationFeedbackState.success
        ) {
            contentUI = (
                <>
                    <div className="flex flex-col self-center justify-center w-full min-h-full px-4">
                        <div className="flex items-center justify-center w-full mt-5 text-center">
                            <FaRegCheckCircle size={'6em'} color={'#F9B041'} />
                        </div>

                        <div className="justify-center w-full mt-5 text-center">
                            <h2
                                className={`font-semibold text-4xl ${styles.EvaluateModalHeader}`}>
                                Your feedback has been submitted!
                            </h2>
                        </div>
                        <div className="justify-center w-full mt-5 text-center">
                            <button
                                className={`inline-block rounded py-1 sm:px-16 font-bold text-lg text-white mr-5 outline-none ${styles.Button} ${styles.ButtonActive}`}
                                onClick={() => {
                                    confirmExitToDashboard();
                                }}>
                                Close
                            </button>
                        </div>
                    </div>
                </>
            );
        } else if (
            !submitPresentationFeedbackState.loading &&
            submitPresentationFeedbackState.error
        ) {
            contentUI = (
                <>
                    <div className="flex flex-col self-center justify-center w-full min-h-full px-4">
                        <div className="flex items-center justify-center w-full mt-5 text-center">
                            <FaRegTimesCircle size={'6em'} color={'#F9B041'} />
                        </div>

                        <div className="justify-center w-full mt-5 text-center">
                            <h2
                                className={`font-semibold text-4xl ${styles.EvaluateModalHeader}`}>
                                Failed to submit your feedback.
                                <br />
                                {!isNetworkOnline()
                                    ? 'You are not online.'
                                    : 'Might be an issue with the server.'}
                            </h2>
                        </div>
                        <div className="justify-center w-full mt-5 text-center">
                            <button
                                className={`inline-block rounded py-1 sm:px-16 font-bold text-lg text-white mr-5 outline-none ${styles.Button}`}
                                onClick={() => {
                                    confirmExitToDashboard();
                                }}>
                                Close
                            </button>
                            <button
                                className={`inline-block rounded py-1 sm:px-16 font-bold text-lg text-white mr-5 outline-none ${styles.Button} ${styles.ButtonActive}`}
                                onClick={() => {
                                    dispatch(submitPresentationFeedbackReset());
                                }}>
                                Retry
                            </button>
                        </div>
                    </div>
                </>
            );
        }
        return (
            <StyledModal
                closeTimeoutMS={300}
                className="ViewPresentationModal"
                isOpen={isEvaluateModalActive}>
                {contentUI}
            </StyledModal>
        );
    };

    const onNextPage = () => {
        if (currentPage < numPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const onPreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const onPageUpdate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const onVideoEnd = () => {
        if (modules) {
            let next;
            const keys = Object.keys(modules);
            for (let i = 0; i < keys.length; i++) {
                if (modules[keys[i]] === id) {
                    next = modules[keys[i + 1]];
                }
            }

            if (next) {
                const postData = {
                    body: { courseId: courseId, nextId: next }
                };
                postMutation.mutate(postData);
            } else {
                if (lastModule) {
                    const postData = {
                        body: { courseId: courseId, nextId: modules[1] }
                    };
                    postMutation.mutate(postData);
                }
            }
        }

        setIsEvaluationEnabled(true);
        setIsVideoEnded(true);
        setIsOnFinishModalActive(true);
        recordAnalyticsEvent('view_video_ended');
    };

    const onVideoStartPlaying = async () => {
        setIsVideoEnded(false);
        recordAnalyticsEvent('view_video_started');
    };

    const recordAnalyticsEvent = async (eventName) => {
        const eventData = {
            url: window.location.origin + window.location.pathname,
            event: eventName,
            date: new Date().toISOString(),
            userId: user.attributes.sub,
            presentationId: id
        };
        console.log('start analytics');
        //record
        try {
            const result = await Analytics.record(
                {
                    data: eventData,
                    streamName:
                        process.env.REACT_APP_AWS_ANALYTICS_KINESIS_FIREHOSE
                },
                'AWSKinesisFirehose'
            );
            console.log(result);
        } catch (e) {
            console.log('analytics failed ' + e);
        }
        console.log('end analytics');
    };

    const onDocumentLoaded = (totalNumberOfPages) => {
        setNumPages(totalNumberOfPages);
        setIsDocumentLoaded(true);
    };

    const onVideoReady = (event) => {
        setIsVideoReady(true);
    };

    const { loading, error, success } = currentPresentationState;
    let internalVideoPlayerClasses = [styles.InVideoPlayerLoader];
    let internalVideoOnlyPresentationPlayerClasses = [
        styles.InVideoOnlyPresentationPlayerLoader
    ];

    if (isVideoReady) {
        internalVideoPlayerClasses.pop();
        internalVideoOnlyPresentationPlayerClasses.pop();
    }

    let containerUI = (
        <>
            <div className={`flex ox-4 sm:px-16 ${styles.Container}`}>
                {!data.isVideoOnlyPresentation ? (
                    <>
                        <div
                            className={`w-2/3 text-center mr-2 justify-center px-5 ${styles.PDFContainer}`}>
                            <PDFView
                                pageNumber={currentPage}
                                data={data}
                                setNumPages={onDocumentLoaded}
                                onNextPage={onNextPage}
                                onPreviousPage={onPreviousPage}
                            />
                        </div>
                        <div
                            className={`w-2/3 sm:w-1/3 my-5 sm:my-0 sm:ml-2 ${styles.RecordingContainer}`}>
                            <VideoPlayer
                                className={internalVideoPlayerClasses.join(' ')}
                                data={data}
                                disabled={!isDocumentLoaded}
                                loading={!isDocumentLoaded}
                                customDisabledMessage={
                                    'Waiting for the PDF to load'
                                }
                                timestamp={data.timestamps}
                                onPageUpdate={onPageUpdate}
                                onVideoEnd={onVideoEnd}
                                onReady={onVideoReady}
                                onVideoStartPlaying={onVideoStartPlaying}>
                                <div
                                    className={`flex items-center justify-center h-full ${styles.InVideoPlayerLoader}`}>
                                    <RingLoader />
                                </div>
                            </VideoPlayer>
                        </div>
                    </>
                ) : (
                    <div
                        className={`w-3/4 sm:w-3/4 my-5 sm:my-0 sm:ml-2 ${styles.VideoOnlyPlayerContainer}`}>
                        <VideoPlayer
                            className={internalVideoOnlyPresentationPlayerClasses.join(
                                ' '
                            )}
                            data={data}
                            customDisabledMessage={''}
                            timestamp={[]}
                            onPageUpdate={() => {}}
                            onVideoEnd={onVideoEnd}
                            onReady={onVideoReady}
                            onVideoStartPlaying={onVideoStartPlaying}>
                            <div
                                className={`flex items-center justify-center h-full ${styles.InVideoOnlyPresentationPlayerLoader}`}>
                                <RingLoader />
                            </div>
                        </VideoPlayer>
                    </div>
                )}
            </div>

            <div className="justify-center w-full mt-5 mb-5 text-center">
                <button
                    className={`inline-block rounded py-1 px-8 sm:px-16 font-bold text-lg text-white mr-5 ${styles.Button}`}
                    onClick={onExit}>
                    Exit
                </button>
                {modules && lastModule ? (
                    <button
                        onClick={() => {
                            setIsEvaluateModalActive(true);
                        }}
                        disabled={!isEvaluationEnabled}
                        className={`inline-block rounded py-1 px-8 sm:px-10 font-bold text-lg text-white ${
                            styles.Button
                        } ${isEvaluationEnabled ? styles.ButtonActive : ''}`}>
                        Evaluate
                    </button>
                ) : null}
                {!modules ? (
                    <button
                        onClick={() => {
                            setIsEvaluateModalActive(true);
                        }}
                        disabled={!isEvaluationEnabled}
                        className={`inline-block rounded py-1 px-8 sm:px-10 font-bold text-lg text-white ${
                            styles.Button
                        } ${isEvaluationEnabled ? styles.ButtonActive : ''}`}>
                        Evaluate
                    </button>
                ) : null}
            </div>

            {welcomeModal}
            {onFinishModal}
            {onExitModal}
            {onEvaluateModal()}
        </>
    );
    if (!loading && !success && error) {
        containerUI = (
            <NoResult message={'Presentation is no longer available'} />
        );
    }
    return (
        <div className={`flex flex-col justify-between h-full`}>
            <Topbar cmsEnabled={false} />
            {containerUI}
            <Footer />
        </div>
    );
};

export default ViewPresentation;
