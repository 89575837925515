/* A simple iframe wrapper that makes 16:9 responsive embed */
import React from 'react';

export default ({
    url,
    background = 'black',
    className = 'video',
    defaultPaddingBottom = '56.25%',
    endTime
}) => {
    if (endTime) {
        url = `${url}#t=0,${endTime}`;
    }

    return (
        <div
            className={`${className} w-100`}
            style={{
                background: background,
                position: 'relative',
                paddingBottom: defaultPaddingBottom,
                height: 0
            }}>
            <iframe
                allowFullScreen
                title="Embeded Video"
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%'
                }}
                src={url}
                frameBorder="0"
            />
        </div>
    );
};
