import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { BsFillPauseFill } from 'react-icons/bs';
import { BsFillPlayFill } from 'react-icons/bs';
import { toLocalWithTwoDigits } from 'shared/utils/number/number';
import _ from 'lodash';

const activeColor = '#283E59';
const inActiveColor = '#828282';

const VideoPlayer = (props) => {
    const playerRef = useRef(null);
    const [timeDurationPlayed, setTimeDurationPlayed] = useState('00:00:00');
    const [isPlaying, setIsPlaying] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [inPlayerClasses, setInPlayerClasses] = useState(null);

    useEffect(() => {
        if (!_.isEqual(props.className, inPlayerClasses)) {
            setInPlayerClasses(props.className);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.className]);

    const onEnded = () => {
        setIsPlaying(false);
        setIsPaused(false);
        props.onVideoEnd();
    };

    const onStartPlaying = () => {
        if (props.disabled) {
            return;
        }

        setIsPlaying(true);
        setIsPaused(false);
    };

    const onPausePlaying = () => {
        setIsPlaying(false);
        setIsPaused(true);
    };

    const onDuration = (duration) => {};

    const onPlay = () => {
        props.onVideoStartPlaying();
    };

    const onReady = (event) => {
        const { onReady } = props;
        if (onReady) {
            onReady(event);
        }
    };

    const onProgress = (progress) => {
        // console.log(progress, 'progress +++++');
        const { timestamp } = props;

        const hours = toLocalWithTwoDigits(Math.floor(progress.playedSeconds / 3600));
        const mins = toLocalWithTwoDigits(Math.floor((progress.playedSeconds / 60) % 60));
        const secs = toLocalWithTwoDigits(Math.floor(progress.playedSeconds % 60));
        setTimeDurationPlayed(`${hours}:${mins}:${secs}`);
        const runingTime = Math.floor(progress.playedSeconds);
        // console.log('Runtime', runingTime);
        const mappedTimeInterval = timestamp.map((ele) => ele.time);
        // console.log(mappedTimeInterval, 'mappedTimeInterval');

        const mappedTime = mappedTimeInterval.reverse().find((e) => e <= runingTime);
        // console.log(mappedTime);

        if (mappedTime === undefined) {
            props.onPageUpdate(1);
        }
        // console.log(runingTime, 'runingTime 1');

        if (timestamp.some((item) => item.time === runingTime)) {
            const item = timestamp.find((x) => x.time === runingTime);
            // console.log(runingTime, 'runingTime 2');
            props.onPageUpdate(item.page);
        }
    };
    const {
        data: { recordedVideo, isVideoEmbed },
        loading
    } = props;

    let contentUI = props.children;
    const videoHeight = isVideoEmbed ? '60vh' : '100%';

    if (recordedVideo && recordedVideo.url && !loading) {
        contentUI = (
            <div className={inPlayerClasses}>
                <ReactPlayer
                    ref={playerRef}
                    url={recordedVideo.url}
                    className={`react-player`}
                    playing={isPlaying}
                    // controls
                    onDuration={onDuration}
                    onProgress={onProgress}
                    onPlay={onPlay}
                    onReady={onReady}
                    // onPaused={() => setIsPaused(true)}
                    onEnded={onEnded}
                    width="100%"
                    height={videoHeight}
                />

                <div className="flex flex-col items-center justify-center">
                    <div
                        className="flex justify-center w-3/5 pb-2 mt-2"
                        style={{ borderBottom: '1px solid #D2DDEC' }}>
                        <span className="text-lg font-semibold" style={{ color: '#6E84A3' }}>
                            {timeDurationPlayed}
                        </span>
                    </div>
                    <div className="flex justify-between w-1/4 my-2">
                        <BsFillPlayFill
                            size={'2em'}
                            color={!isPlaying ? activeColor : inActiveColor}
                            onClick={onStartPlaying}
                        />
                        <BsFillPauseFill
                            size={'2em'}
                            color={!isPaused && isPlaying ? activeColor : inActiveColor}
                            onClick={onPausePlaying}
                        />
                    </div>
                </div>

                {props.disabled ? (
                    <div className="flex flex-col items-center justify-center">
                        <div className="flex justify-center w-3/5 pb-2 mt-2">
                            <span className="text-lg font-semibold" style={{ color: '#6E84A3' }}>
                                {props.customDisabledMessage}
                            </span>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }

    return contentUI;
};

export default VideoPlayer;
