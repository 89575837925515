import React, { useState } from 'react';
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import { Container as ContainerBase } from 'components/misc/Layouts';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
// import illustration from 'images/signup-illustration.svg';
import logo from 'images/logo.svg';
// import googleIconImageSrc from 'images/google-icon.png';
// import twitterIconImageSrc from 'images/twitter-icon.png';
//import { ReactComponent as SignUpIcon } from 'feather-icons/dist/icons/user-plus.svg';
import { Auth } from 'aws-amplify';
import { useHistory, useParams, useLocation } from 'react-router-dom';
// import { useUser } from 'util/auth.js';

const Container = tw(
    ContainerBase
)`min-h-screen bg-primary-100 text-white font-medium flex justify-center`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
// const MainContent = tw.div`mt-12 flex flex-col items-center`;
// const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
    ${tw`bg-primary-500 hover:bg-primary-900 focus:shadow-outline focus:outline-none flex items-center justify-center w-full py-4 mt-5 font-semibold tracking-wide text-gray-100 transition-all duration-300 ease-in-out rounded-lg`}
    .icon {
        ${tw`w-6 h-6 -ml-2`}
    }
    .text {
        ${tw`ml-3`}
    }
`;

const Logo = ({ logoLinkUrl = '/' }) => {
    const history = useHistory();
    const LogoLink = tw.a``;
    const LogoImage = tw.img`h-20 mx-auto`;
    let newLogo = '';
    let { org } = useParams();
    const onError = (e) => {
        //redirect to signup if org logo is not found
        history.push('/signup');
    };

    if (org) {
        //get image from S3 bucket
        console.log(org);
        newLogo = `${process.env.REACT_APP_PROFILE_IMAGES_URL}/org-info/${org}/logo`;
    }
    return (
        <LogoLink href={logoLinkUrl}>
            <LogoImage src={org ? newLogo : logo} onError={onError} />
        </LogoLink>
    );
};

const FormSignup = ({
    submitButtonText = 'Sign Up',
    // resendButtonText = 'Resend verification code',
    // SubmitButtonIcon = SignUpIcon,
    // verifyButtonText = 'Verify',
    tosUrl = '/tos',
    privacyPolicyUrl = '/privacy',
    signInUrl = '/login',
    resendUrl = '/resend'
}) => {
    // const history = useHistory();
    const location = useLocation();
    const search = new URLSearchParams(location.search);
    const inviteCode = search.get('invite_code') || '';
    const [name, setName] = useState('');
    const [familyName, setFamilyName] = useState('');
    const [occupation, setoccupation] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [success, setSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [logError, setLogError] = useState('');
    const [infoMsg, setInfoMsg] = useState('');
    const [pwdError, setPwdError] = useState(false);

    const pwdRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/g;

    const nameChange = (event) => setName(event.target.value);
    const familyNameChange = (event) => setFamilyName(event.target.value);
    const occupationChange = (event) => setoccupation(event.target.value);
    const emailChange = (event) => setEmail(event.target.value.trim());
    const passwordChange = (event) => setPassword(event.target.value.trim());

    function validateEmail(email) {
        const re = /^[^\s@]+@[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    }

    async function signUp(e) {
        e.preventDefault();

        if (
            email.length > 0 &&
            password.length > 0 &&
            name.length > 0 &&
            familyName.length > 0 &&
            occupation.length > 0
        ) {
            const validEmail = validateEmail(email);
            const pwdFound = password.match(pwdRegex);

            if (validEmail === false) {
                setLogError('Input valid email');
            } else if (password.length < 8 || pwdFound === null) {
                setLogError('See password requirements');
                setPwdError(true);
            } else {
                setIsLoading(true);
                try {
                    const username = email;
                    const family_name = familyName;
                    const { user } = await Auth.signUp({
                        username,
                        password,
                        attributes: {
                            email,
                            name,
                            family_name,
                            'custom:occupation': occupation
                        },
                        clientMetadata: { inviteCode: inviteCode }
                    });
                    console.log(user);
                    setLogError('');
                    setSuccess(true);
                    setIsLoading(false);
                } catch (error) {
                    console.log('error signing up:', error);
                    setSuccess(false);
                    setIsLoading(false);
                    setLogError(error.message);
                }
            }
        } else {
            setLogError('Fill in all the fields');
        }
    }

    return (
        <Form>
            {logError ? <p style={{ color: 'red', textAlign: 'center' }}>{logError}</p> : null}
            {!success ? (
                <>
                    <Input
                        type="text"
                        style={{
                            borderColor: logError && name.length === 0 ? 'red' : ''
                        }}
                        required
                        placeholder="First name"
                        value={name}
                        onChange={nameChange}
                    />
                    <Input
                        type="text"
                        style={{
                            borderColor: logError && familyName.length === 0 ? 'red' : ''
                        }}
                        required
                        placeholder="Last name"
                        value={familyName}
                        onChange={familyNameChange}
                    />
                    <Input
                        type="text"
                        style={{
                            borderColor: logError && occupation.length === 0 ? 'red' : ''
                        }}
                        required
                        placeholder="Occupation"
                        value={occupation}
                        onChange={occupationChange}
                    />
                    <Input
                        type="email"
                        style={{
                            borderColor: logError && email.length === 0 ? 'red' : ''
                        }}
                        required
                        placeholder="Email"
                        value={email}
                        onChange={emailChange}
                    />
                    <Input
                        type="password"
                        style={{
                            borderColor: logError && password.length < 8 ? 'red' : ''
                        }}
                        required
                        placeholder="Password"
                        value={password}
                        onChange={passwordChange}
                    />
                    <p
                        style={pwdError ? { color: 'red' } : {}}
                        tw="mt-6 text-xs text-gray-800 text-center">
                        Required: Password length at least 8 characters. <br /> A lowercase letter,
                        an uppercase letter, a number.
                    </p>
                    <SubmitButton type="submit" onClick={signUp}>
                        {isLoading ? (
                            <div className="lds-ring">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        ) : (
                            <span className="text">{submitButtonText}</span>
                        )}
                    </SubmitButton>
                    <p tw="mt-6 text-xs text-gray-600 text-center">
                        I agree to abide by Beaker health's{' '}
                        <a href={tosUrl} tw="border-b border-gray-500 border-dotted">
                            Terms of Service
                        </a>{' '}
                        and its{' '}
                        <a href={privacyPolicyUrl} tw="border-b border-gray-500 border-dotted">
                            Privacy Policy
                        </a>
                    </p>

                    <p tw="mt-6 text-sm text-gray-600 text-center">
                        Already have an account?{' '}
                        <a href={signInUrl} tw="border-b border-gray-500 border-dotted">
                            Log In
                        </a>
                    </p>

                    <p tw="mt-2 text-sm text-gray-600 text-center">
                        Resend verification email?{' '}
                        <a href={resendUrl} tw="border-b border-gray-500 border-dotted">
                            Resend
                        </a>
                    </p>
                </>
            ) : (
                <>
                    {!logError && !infoMsg ? (
                        <>
                            <p tw="text-center">You have registered successfully.</p>
                            <p tw="text-center">Check your email for verification link</p>
                        </>
                    ) : (
                        <>
                            <br />
                        </>
                    )}
                    {!logError && infoMsg ? <p>{infoMsg}</p> : null}
                    <p tw="mt-2 text-sm text-gray-600 text-center">
                        Resend verification email?{' '}
                        <a href={resendUrl} tw="border-b border-gray-500 border-dotted">
                            Resend
                        </a>
                    </p>
                </>
            )}
        </Form>
    );
};

export default () => (
    <AnimationRevealPage>
        <Container>
            <Content>
                <MainContainer>
                    <Logo />
                    <FormContainer>
                        <FormSignup />
                    </FormContainer>
                </MainContainer>
            </Content>
        </Container>
    </AnimationRevealPage>
);
