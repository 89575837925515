import React, { useState, useEffect } from 'react';
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import { Container as ContainerBase } from 'components/misc/Layouts';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
// import illustration from 'images/signup-illustration.svg';
import logo from 'images/logo.svg';
// import googleIconImageSrc from 'images/google-icon.png';
// import twitterIconImageSrc from 'images/twitter-icon.png';
// import { ReactComponent as SignUpIcon } from 'feather-icons/dist/icons/user-plus.svg';
import { Auth } from 'aws-amplify';
// import { useHistory } from 'react-router-dom';
// import { useUser } from 'util/auth.js';

const Container = tw(
    ContainerBase
)`min-h-screen bg-primary-100 text-white font-medium flex justify-center`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-20 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
    ${tw`bg-primary-500 hover:bg-primary-900 focus:shadow-outline focus:outline-none flex items-center justify-center w-full py-4 mt-5 font-semibold tracking-wide text-gray-100 transition-all duration-300 ease-in-out rounded-lg`}
    .icon {
        ${tw`w-6 h-6 -ml-2`}
    }
    .text {
        ${tw`ml-3`}
    }
`;

const FormSignup = ({
    resendButtonText = 'Reset password',
    tosUrl = '/tos',
    privacyPolicyUrl = '/privacy',
    signInUrl = '/login'
}) => {
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [logError, setLogError] = useState('');
    const [infoMsg, setInfoMsg] = useState('');

    const [timeLeft, setTimeLeft] = useState(0);

    useEffect(() => {
        // exit early when we reach 0
        if (!timeLeft) return;

        // save intervalId to clear the interval when the
        // component re-renders
        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
        // add timeLeft as a dependency to re-rerun the effect
        // when we update it
    }, [timeLeft]);

    const emailChange = (event) => setEmail(event.target.value);

    async function resendConfirmationCode(e) {
        e.preventDefault();
        setIsLoading(true);
        try {
            await Auth.forgotPassword(email);
            setInfoMsg('Check your email for a reset link');
            setTimeLeft(60);
            console.log('code resent successfully');
            setIsLoading(false);
        } catch (err) {
            console.log('error resending code: ', err);
            setLogError('error resending code: ' + err);
            setIsLoading(false);
        }
    }

    return (
        <Form>
            {logError ? (
                <p style={{ color: 'red', textAlign: 'center' }}>{logError}</p>
            ) : null}
            <span>
                {!logError && infoMsg ? (
                    <p tw="text-center">{infoMsg}</p>
                ) : null}
                <Input
                    type="email"
                    style={{
                        borderColor: logError && email.length === 0 ? 'red' : ''
                    }}
                    required
                    placeholder="Email"
                    value={email}
                    onChange={emailChange}
                />
                <SubmitButton
                    type="button"
                    onClick={resendConfirmationCode}
                    disabled={timeLeft !== 0}
                    className={timeLeft !== 0 ? 'opacity-50' : ''}>
                    {timeLeft !== 0 ? (
                        <span className="text">{timeLeft}</span>
                    ) : (
                        <span className="text">{resendButtonText}</span>
                    )}
                </SubmitButton>

                <p tw="mt-8 text-sm text-gray-600 text-center">
                    Already have an account?{' '}
                    <a
                        href={signInUrl}
                        tw="border-b border-gray-500 border-dotted">
                        Log In
                    </a>
                </p>
            </span>
        </Form>
    );
};

export default ({ logoLinkUrl = '/', headingText = '' }) => (
    <AnimationRevealPage>
        <Container>
            <Content>
                <MainContainer>
                    <LogoLink href={logoLinkUrl}>
                        <LogoImage src={logo} />
                    </LogoLink>
                    <MainContent>
                        <Heading>{headingText}</Heading>
                        <FormContainer>
                            <FormSignup />
                        </FormContainer>
                    </MainContent>
                </MainContainer>
            </Content>
        </Container>
    </AnimationRevealPage>
);
