import * as actionTypes from '../actions/user/actionTypes';
import { updateObject } from 'shared/utils/object';

const initialState = {
    userProfile: {
        loading: false,
        success: false,
        error: null,
        data: {
            title: '',
            credentials: '',
            profileImage: '',
            fName: '',
            lName: '',
            occupation: '',
            language: '',
            linkedin: '',
            twitter: '',
            facebook: '',
            website: '',
            aboutMe: ''
        }
    },
    userProfileImage: {
        loading: false,
        success: false,
        error: null,
        data: null
    },
    certificateForPresentation: {
        loading: false,
        success: false,
        error: null,
        data: null
    }
};

const getUserProfileStart = (state, action) => {
    const newState = updateObject(state, {
        userProfile: updateObject(state.userProfile, {
            loading: true,
            error: null,
            success: false,
            data: {
                title: '',
                credentials: '',
                profileImage: '',
                first_name: '',
                last_name: '',
                occupation: '',
                language: '',
                linkedin_url: '',
                twitter_url: '',
                fb_url: '',
                website_url: '',
                about_me: ''
            }
        })
    });
    return newState;
};

const getUserProfileSuccess = (state, action) => {
    const newState = updateObject(state, {
        userProfile: updateObject(state.userProfile, {
            loading: false,
            error: null,
            success: true,
            data: {
                ...state.userProfile.data,
                ...action.payload
            }
        })
    });
    return newState;
};

const getUserProfileFail = (state, action) => {
    const newState = updateObject(state, {
        userProfile: updateObject(state.userProfile, {
            loading: false,
            error: action.payload.error,
            success: false,
            data: {
                title: '',
                credentials: '',
                profileImage: '',
                first_name: '',
                last_name: '',
                occupation: '',
                language: '',
                linkedin_url: '',
                twitter_url: '',
                fb_url: '',
                website_url: '',
                about_me: ''
            }
        })
    });
    return newState;
};

const saveUserProfileInfoStart = (state, action) => {
    const newState = updateObject(state, {
        userProfile: updateObject(state.userProfile, {
            loading: true,
            error: null,
            success: false
        })
    });
    return newState;
};

const saveUserProfileInfoSuccess = (state, action) => {
    const newState = updateObject(state, {
        userProfile: updateObject(state.userProfile, {
            loading: false,
            error: null,
            success: true
        })
    });
    return newState;
};

const saveUserProfileInfoFail = (state, action) => {
    const newState = updateObject(state, {
        userProfile: updateObject(state.userProfile, {
            loading: false,
            error: action.payload.error,
            success: false
        })
    });
    return newState;
};

const saveUserProfilePictureStart = (state, action) => {
    const newState = updateObject(state, {
        userProfileImage: updateObject(state.userProfileImage, {
            loading: true,
            error: null,
            success: false
        })
    });
    return newState;
};

const saveUserProfilePictureSuccess = (state, action) => {
    const newState = updateObject(state, {
        userProfileImage: updateObject(state.userProfileImage, {
            loading: false,
            error: null,
            success: true,
            data: action.payload
        })
    });
    return newState;
};

const saveUserProfilePictureFail = (state, action) => {
    const newState = updateObject(state, {
        userProfileImage: updateObject(state.userProfileImage, {
            loading: false,
            error: action.payload.error,
            success: false
        })
    });
    return newState;
};

// Certificate
const getCertificateForUserPresentationStart = (state, action) => {
    const newState = updateObject(state, {
        certificateForPresentation: updateObject(
            state.certificateForPresentation,
            {
                loading: true,
                error: null,
                success: false,
                data: null
            }
        )
    });
    return newState;
};

const getCertificateForUserPresentationSuccess = (state, action) => {
    const newState = updateObject(state, {
        certificateForPresentation: updateObject(
            state.certificateForPresentation,
            {
                loading: false,
                error: null,
                success: true,
                data: action.payload
            }
        )
    });
    return newState;
};

const getCertificateForUserPresentationFail = (state, action) => {
    const newState = updateObject(state, {
        certificateForPresentation: updateObject(
            state.certificateForPresentation,
            {
                loading: false,
                error: action.payload.error,
                success: false
            }
        )
    });
    return newState;
};

const getCertificateForUserPresentationReset = (state, action) => {
    const newState = updateObject(state, {
        certificateForPresentation: updateObject(
            state.certificateForPresentation,
            {
                loading: false,
                error: null,
                success: false,
                data: null
            }
        )
    });
    return newState;
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_USER_PROFILE_START:
            return getUserProfileStart(state, action);
        case actionTypes.GET_USER_PROFILE_SUCCESS:
            return getUserProfileSuccess(state, action);
        case actionTypes.GET_USER_PROFILE_FAIL:
            return getUserProfileFail(state, action);

        case actionTypes.ON_SAVE_USER_PROFILE_INFO_START:
            return saveUserProfileInfoStart(state, action);
        case actionTypes.ON_SAVE_USER_PROFILE_INFO_SUCCESS:
            return saveUserProfileInfoSuccess(state, action);
        case actionTypes.ON_SAVE_USER_PROFILE_INFO_FAIL:
            return saveUserProfileInfoFail(state, action);

        case actionTypes.ON_SAVE_USER_PROFILE_PICTURE_START:
            return saveUserProfilePictureStart(state, action);
        case actionTypes.ON_SAVE_USER_PROFILE_PICTURE_SUCCESS:
            return saveUserProfilePictureSuccess(state, action);
        case actionTypes.ON_SAVE_USER_PROFILE_PICTURE_FAIL:
            return saveUserProfilePictureFail(state, action);

        case actionTypes.ON_GET_CERTIFICATE_FOR_USER_PRESENTATION_START:
            return getCertificateForUserPresentationStart(state, action);
        case actionTypes.ON_GET_CERTIFICATE_FOR_USER_PRESENTATION_SUCCESS:
            return getCertificateForUserPresentationSuccess(state, action);
        case actionTypes.ON_GET_CERTIFICATE_FOR_USER_PRESENTATION_FAIL:
            return getCertificateForUserPresentationFail(state, action);
        case actionTypes.ON_GET_CERTIFICATE_FOR_USER_PRESENTATION_RESET:
            return getCertificateForUserPresentationReset(state, action);

        default:
            return state;
    }
};

export default reducer;
