import * as actionTypes from './actionTypes';
import { API } from 'aws-amplify';
import axios from 'axios';

export const getPresentationStart = () => {
    return {
        type: actionTypes.GET_PRESENTATION_START
    };
};

export const getPresentationSuccess = (data) => {
    return {
        type: actionTypes.GET_PRESENTATION_SUCCESS,
        payload: data
    };
};

export const getPresentationFail = (error) => {
    return {
        type: actionTypes.GET_PRESENTATION_FAIL,
        payload: { error }
    };
};

export const getPresentationReset = () => {
    return {
        type: actionTypes.GET_PRESENTATION_RESET
    };
};

export const getPresentation = (id) => {
    return (dispatch, getState) => {
        dispatch(getPresentationStart());

        API.get(process.env.REACT_APP_API_ENDPOINT_NAME, '/presentation/' + id)
            .then((response) => {
                dispatch(getPresentationSuccess(response));
            })
            .catch((error) => {
                console.log(error.response);
                dispatch(getPresentationFail(error));
            });
    };
};

// Submit Presentation Feedback
export const submitPresentationFeedbackStart = () => {
    return {
        type: actionTypes.SUBMIT_PRESENTATION_FEEDBACK_START
    };
};

export const submitPresentationFeedbackSuccess = (data) => {
    return {
        type: actionTypes.SUBMIT_PRESENTATION_FEEDBACK_SUCCESS,
        payload: data
    };
};

export const submitPresentationFeedbackFail = (error) => {
    return {
        type: actionTypes.SUBMIT_PRESENTATION_FEEDBACK_FAIL,
        payload: { error }
    };
};

export const submitPresentationFeedbackReset = () => {
    return {
        type: actionTypes.SUBMIT_PRESENTATION_FEEDBACK_RESET
    };
};

export const submitPresentationFeedback = (id, body) => {
    return (dispatch, getState) => {
        dispatch(submitPresentationFeedbackStart());

        API.post(
            process.env.REACT_APP_API_ENDPOINT_NAME,
            '/presentation/' + id + '/review',
            { body }
        )
            .then((response) => {
                dispatch(submitPresentationFeedbackSuccess(response));
            })
            .catch((error) => {
                console.log(error.response);
                dispatch(submitPresentationFeedbackFail(error));
            });
    };
};

export const getPresentationInfoStart = () => {
    return {
        type: actionTypes.GET_PRESENTATION_INFO_START
    };
};

export const getPresentationInfoSuccess = (data) => {
    return {
        type: actionTypes.GET_PRESENTATION_INFO_SUCCESS,
        payload: data
    };
};

export const getPresentationInfoFail = (error) => {
    return {
        type: actionTypes.GET_PRESENTATION_INFO_FAIL,
        payload: { error }
    };
};

export const getPresentationInfo = (id) => {
    return (dispatch, getState) => {
        dispatch(getPresentationInfoStart());

        API.get(
            process.env.REACT_APP_API_ENDPOINT_NAME_PUBLIC,
            '/presentation/' + id + '/info'
        )
            .then((response) => {
                dispatch(getPresentationInfoSuccess(response));
            })
            .catch((error) => {
                console.log(error.response);
                dispatch(getPresentationInfoFail(error));
            });
    };
};

// Get Presentations
export const getUserPresentationsStart = () => {
    return {
        type: actionTypes.GET_USER_PRESENTATIONS_START
    };
};

export const getUserPresentationsSuccess = (data) => {
    return {
        type: actionTypes.GET_USER_PRESENTATIONS_SUCCESS,
        payload: data
    };
};

export const getUserPresentationsFail = (error) => {
    return {
        type: actionTypes.GET_USER_PRESENTATIONS_FAIL,
        payload: { error }
    };
};

export const getUserPresentations = (value) => {
    return (dispatch, getState) => {
        dispatch(getUserPresentationsStart());

        API.get(process.env.REACT_APP_API_ENDPOINT_NAME, '/user/dash')
            .then(function (response) {
                const sortedArray = response
                    ? response.sort(function (a, b) {
                          return (
                              new Date(b.review.updated) -
                              new Date(a.review.updated)
                          );
                      })
                    : [];
                dispatch(getUserPresentationsSuccess(sortedArray));
            })
            .catch(function (error) {
                dispatch(getUserPresentationsFail(error));
            });
    };
};
