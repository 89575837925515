import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Modal from 'react-modal';

import * as Cookies from 'js-cookie';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import { UserProvider } from 'util/auth.js';
import presentationsReducer from 'state/reducers/presentations';
import userReducer from 'state/reducers/user';
import thunk from 'redux-thunk';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import LogRocket from 'logrocket';

import Amplify, { Auth, Analytics, AWSKinesisFirehoseProvider } from 'aws-amplify';
import awsconfig from './aws-exports';

if (!awsconfig.aws_cognito_region) {
    awsconfig = {
        aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
        aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
        aws_cognito_region: process.env.REACT_APP_AWS_PROJECT_REGION,
        aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
        aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
        oauth: {},
        aws_firehose_name: process.env.REACT_APP_AWS_ANALYTICS_KINESIS_FIREHOSE
    };
}

Analytics.addPluggable(new AWSKinesisFirehoseProvider());
Amplify.configure(awsconfig);

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

if (isLocalhost) {
    // dev code
    var authSettings = {
        cookieStorage: {
            domain: 'localhost',
            path: '/',
            expires: 1,
            sameSite: 'lax',
            secure: false
        }
    };
} else {
    // production code
    var authSettings = {
        region: process.env.REACT_APP_AWS_PROJECT_REGION,
        identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
        identityPoolRegion: process.env.REACT_APP_AWS_COGNITO_REGION,
        userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
        userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
        // OPTIONAL - Configuration for cookie storage
        // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
        cookieStorage: {
            // REQUIRED - Cookie domain (only required if cookieStorage is provided)
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            // OPTIONAL - Cookie path
            path: '/',
            // OPTIONAL - Cookie expiration in days
            expires: 1,
            // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
            sameSite: 'lax',
            // OPTIONAL - Cookie secure flag
            // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
            secure: true
        }
    };

    Sentry.init({
        dsn: 'https://faa510dadd344312b043f6786360627c@o589122.ingest.sentry.io/5739376',
        integrations: [new Integrations.BrowserTracing()],

        // Set tracesSampleRate to 0.5 to capture 50% perf data
        tracesSampleRate: 0.5,
        release: 'beaker-health-home-prod@1.0.1'
    });

    LogRocket.init('nctwo4/beakerhealth-scholar-prod');
    LogRocket.getSessionURL((sessionURL) => {
        Sentry.configureScope((scope) => {
            scope.setExtra('sessionURL', sessionURL);
        });
    });
}

const apiEndpoints = {
    endpoints: [
        {
            name: process.env.REACT_APP_API_ENDPOINT_NAME,
            endpoint: process.env.REACT_APP_API_ENDPOINT_URL,
            custom_header: async () => {
                return {
                    Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                };
            }
        },
        {
            name: process.env.REACT_APP_API_ENDPOINT_NAME_PUBLIC,
            endpoint: process.env.REACT_APP_API_ENDPOINT_URL
        }
    ]
};

Amplify.configure({
    Auth: authSettings,
    API: apiEndpoints,
    AWSKinesisFirehose: {
        region: awsconfig.aws_project_region,
        flushInterval: 1000,
        resendLimit: 5
    }
});

//setup compression
class MyStorage {
    // set item with the key
    static setItem(key, value) {
        var options = authSettings.cookieStorage;
        const tokenType = key.split('.').pop();
        if (tokenType === 'accessToken' || tokenType === 'userData') {
            localStorage.setItem(key, value);
            return localStorage.getItem(key);
        }

        Cookies.set(key, value, options);
        return Cookies.get(key);
    }
    // get item with the key
    static getItem(key) {
        const tokenType = key.split('.').pop();
        if (tokenType === 'accessToken' || tokenType === 'userData') {
            return localStorage.getItem(key);
        }
        return Cookies.get(key);
    }
    static removeItem(key) {
        var options = authSettings.cookieStorage;
        localStorage.removeItem(key);
        return Cookies.remove(key, options);
    }
    static clear() {
        const cookies = Cookies.get();
        let index;
        for (index = 0; index < cookies.length; ++index) {
            Cookies.remove(cookies[index]);
        }
        localStorage.clear();
        return {};
    }
}

// tell Auth to use your storage object
Auth.configure({
    storage: MyStorage
});

Modal.setAppElement('#root');

const rootReducer = combineReducers({
    presentations: presentationsReducer,
    user: userReducer
});

const composeEnhancers =
    process.env.NODE_ENV === 'development'
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
        : compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

const queryClient = new QueryClient();

const app = (
    <Provider store={store}>
        <UserProvider>
            <QueryClientProvider client={queryClient}>
                <App />
            </QueryClientProvider>
        </UserProvider>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
