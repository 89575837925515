import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { RingLoader } from 'components/shared';
import { SizeMe } from 'react-sizeme';
import { useMediaQuery } from 'react-responsive';
import styles from './PDFView.module.css';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PDFViewComponent(props) {
    const [numPages, setNumPages] = useState(null);
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        props.setNumPages(numPages);
    }

    const { pdfData } = props.data;
    const { pageNumber } = props;

    let pdfCanvasHeight = 450;

    const isDesktopOrLaptopHD = useMediaQuery({
        query: '(max-device-width: 1280px)'
    });

    const isDesktopOrLaptopHDPlus = useMediaQuery({
        query: '(max-device-width: 1366px)'
    });

    const isDesktopOrLaptopHiDpi = useMediaQuery({
        query: '(max-device-width: 1440px)'
    });

    if (isDesktopOrLaptopHD) {
        pdfCanvasHeight = 350;
    } else if (isDesktopOrLaptopHDPlus) {
        pdfCanvasHeight = 380;
    } else if (isDesktopOrLaptopHiDpi) {
        pdfCanvasHeight = 393;
    }

    const inPDFLoader = (
        <div className={`${styles.PdfPageLoader}`}>
            <RingLoader />
        </div>
    );

    return (
        <div className={`flex flex-col space-between flex-1 h-full`}>

            <div style={{ minHeight: pdfCanvasHeight, height: pdfCanvasHeight }} className={styles.Container}>
                {/* {numPages == null ? (
                    <div className={`${styles.PdfPageLoader}`}>
                        <RingLoader />
                    </div>
                ) : null} */}

                <SizeMe>
                    {({ size }) => {
                        return (
                            <Document
                                className={styles.Document}
                                file={pdfData.url}
                                onLoadSuccess={onDocumentLoadSuccess}
                                loading={inPDFLoader}
                                noData={inPDFLoader}>
                                <Page
                                    className={styles.Page}
                                    size="A4"
                                    pageNumber={pageNumber}
                                    height={
                                        size.height
                                            ? size.height / 2
                                            : pdfCanvasHeight
                                    }
                                    scale={1}
                                    orientation="landscape"
                                    loading={null}
                                />
                            </Document>
                        );
                    }}
                </SizeMe>
            </div>
            <div className={`flex justify-center p-5 ${styles.PdfFooter}`}>
                <p className={`mx-5 text-base font-bold ${styles.PageNumber}`}>
                    {pageNumber}/{numPages}
                </p>
            </div>
        </div>
    );
}

export default PDFViewComponent;
