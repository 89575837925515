import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Topbar from 'components/headers/Topbar/Topbar.js';
import Footer from 'components/footers/UserFooter.js';
import Box from '@material-ui/core/Box';
import styles from './UserDashboard.module.css';
import { Grid } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { Rating } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { getUserPresentations } from 'state/actions/presentations/presentations';
import { getUserProfile, getCertificateForUserPresentation } from 'state/actions/user/user';
import { useDispatch, useSelector } from 'react-redux';
import { FaRegFileAlt } from 'react-icons/fa';
import { BiSearchAlt2 } from 'react-icons/bi';
import { commonUTCDateFormat } from 'shared/utils/date';
import Fuse from 'fuse.js';
import PresentationImageFallback from 'images/sample/presentation/presentation-hero-image.png';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiDataGrid-root': {
            border: 0
        },
        '& .MuiDataGrid-footer': {
            justifyContent: 'center'
        },
        '& .MuiDataGrid-colCellTitle': {
            color: '#283E59',
            fontWeight: 300,
            fontSize: 12
        },
        '& .MuiDataGrid-colCellTitleContainer': {
            justifyContent: 'center'
        },
        '& .MuiDataGrid-cellWithRenderer': {
            justifyContent: 'center'
        },
        '& .MuiDataGrid-viewport': {
            minWidth: '100% !important'
        },
        '& .MuiDataGrid-dataContainer': {
            minWidth: '100% !important'
        }
    }
}));

const UserDashboard = () => {
    const [presentations, setPresentations] = useState([]);
    const [filtered, setFiltered] = useState(false);
    const [fuse, setFuse] = useState(null);
    const dispatch = useDispatch();
    const history = useHistory();
    const userProfileState = useSelector((state) => state.user.userProfile);
    const userPresentationsState = useSelector((state) => state.presentations.userPresentations);

    const fuseOptions = {
        includeScore: true,
        keys: ['title']
    };

    let timer = 0;
    const keyUpSearch = (e) => {
        e.persist();
        clearTimeout(timer);
        timer = setTimeout(() => {
            search(e.target.value);
        }, 250);
    };

    const search = (value) => {
        let result = fuse.search(value);
        if (!result.length) {
            setFiltered(false);
        } else {
            result = result.map((i) => i.item);
            setFiltered(result);
        }
    };

    const OnViewUserPresentationCertificateHandler = (presentation) => {
        const { videoDuration, title, review } = presentation;
        let name = '';
        if (userProfileState.data) {
            const { first_name, last_name } = userProfileState.data;
            name = `${first_name} ${last_name}`;
            name.trim();
        }

        let presentationDurationInHours = 0;
        if (videoDuration) {
            const timeSlices = videoDuration.slice(0, 5).split(':');
            presentationDurationInHours = (
                +timeSlices[0] + (+timeSlices[1] > 0 ? +timeSlices[1] / 60 : +timeSlices[1])
            ).toFixed(2);
        }

        let completionDate = review.updated.slice(0, 10);

        dispatch(
            getCertificateForUserPresentation({
                name,
                title,
                presentationDurationInHours,
                completionDate
            })
        );
        history.push('/certificate/');
    };

    useEffect(() => {
        dispatch(getUserPresentations());
        dispatch(getUserProfile());
        return () => {};
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const { data } = userPresentationsState;
        if (data instanceof Array && data) {
            const presentations = data.map((presentation) => {
                return {
                    id: presentation.review.id,
                    overallReviews: presentation.review.starsAvg,
                    ...presentation
                };
            });
            setPresentations(presentations);
            setFuse(new Fuse(presentations, fuseOptions));
        } else {
            setPresentations([]);
        }
    }, [userPresentationsState]);

    const ProfileImage = React.memo(({ userId }) => {
        let userImg = '';
        if (userId) {
            userImg =
                process.env.REACT_APP_PROFILE_IMAGES_URL +
                '/users/' +
                userId +
                '/picture/profile.jpg';
        }
        function imageError(evt) {
            evt.target.src = PresentationImageFallback;
        }
        return (
            <img
                className={`${styles.UserAvatar}`}
                src={userImg}
                onError={imageError}
                alt="User avatar"></img>
        );
    });

    const ThumbnailImage = React.memo(({ presentationId }) => {
        let thumbnailSrc = '';
        if (presentationId) {
            thumbnailSrc =
                process.env.REACT_APP_PRESENTATION_THUMBNAILS_URL +
                '/thumbnails/presentations/' +
                presentationId +
                '/thumbnail.jpeg';
        }
        function imageError(evt) {
            evt.target.src = require(`../../images/sample-datagrid-image.png`);
        }
        return (
            <img
                className={`${styles.PresentationThumbnailImage}`}
                src={thumbnailSrc}
                onError={imageError}
                alt="Presentation Thumbnail"></img>
        );
    });

    const columns = [
        {
            field: 'presentations',
            headerName: 'Modules',
            flex: 0.8,
            renderCell: (params) => {
                params.row.first_name = params.row.first_name ? params.row.first_name : '';
                params.row.last_name = params.row.last_name ? params.row.last_name : '';
                let name = `${params.row.first_name} ${params.row.last_name}`;
                name.trim();

                const title = params.row.title ? params.row.title : '';

                return (
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        style={{ padding: 5 }}
                        spacing={0}
                        alignItems="center">
                        <Grid item sm={4} lg={3} xl={2}>
                            <div className={styles.DatagridPresentationImage}>
                                <ThumbnailImage presentationId={params.row.pid} />
                            </div>
                        </Grid>
                        <Grid item sm={8} lg={9} xl={10}>
                            <Box component="div" style={{ marginLeft: 10 }}>
                                <span
                                    className={`${styles.PresentationsColumnSpan} ${styles.PresentationsColumnSpanTitle}`}>
                                    {title}
                                </span>
                                <div className={`${styles.UserAvatarContainer}`}>
                                    <ProfileImage userId={params.row.ownerId} />
                                    <span
                                        className={`${styles.PresentationsColumnSpan} ${styles.PresentationsColumnSpanDate}`}>
                                        {name}
                                    </span>
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                );
            },
            disableColumnMenu: true,
            disableColumnFilter: true,
            sortable: false
        },
        {
            field: 'date',
            headerName: 'Date',
            width: 130,
            renderCell: (params) => (
                <div className={styles.Reviews}>
                    <span className={styles.UserHomeDetails}>
                        {params.row.review.updated
                            ? commonUTCDateFormat(params.row.review.updated)
                            : '-'}
                    </span>
                </div>
            ),
            disableColumnMenu: true,
            disableColumnFilter: true,
            sortable: false
        },
        {
            field: 'hours',
            headerName: 'Hours',
            width: 130,
            renderCell: (params) => (
                <div className={styles.Reviews}>
                    <span className={styles.UserHomeDetails}>
                        {params.row.videoDuration ? params.row.videoDuration.slice(0, 5) : '-'}
                    </span>
                </div>
            ),
            disableColumnMenu: true,
            disableColumnFilter: true,
            sortable: false
        },
        {
            field: 'userReview',
            headerName: 'Your Review',
            width: 180,
            renderCell: (params) => (
                <div className={styles.Reviews}>
                    <Rating
                        name="custom-rating-filter-operator"
                        placeholder="Filter value"
                        disabled
                        value={Number(params.row.review.starsAvg)}
                        precision={0.5}
                    />
                </div>
            ),
            disableColumnMenu: true,
            disableColumnFilter: true,
            sortable: false
        },
        {
            field: 'certificate',
            headerName: 'Certificate',
            width: 100,
            renderCell: (params) => (
                <FaRegFileAlt
                    size={'1.5em'}
                    className={styles.ViewPresentationCertificate}
                    onClick={() => {
                        OnViewUserPresentationCertificateHandler(params.row);
                    }}></FaRegFileAlt>
            ),
            disableColumnMenu: true,
            disableColumnFilter: true,
            sortable: false
        }
    ];

    const classes = useStyles();
    const { loading, error, success } = userPresentationsState;

    return (
        <div className="App">
            <Topbar cmsEnabled={true} />

            <Grid container direction="row" justifyContent="center" spacing={0}>
                <Grid item sm={9} lg={9}>
                    <Grid container direction="row" justifyContent="center">
                        <Grid item sm={8} lg={6} className={`${styles.HeroContainer}`}>
                            <p className={styles.Title}>Search from your modules</p>

                            <div
                                className={`bg-white flex items-center p-2 rounded-md my-12 w-full ${styles.SearchBar}`}>
                                <input
                                    onKeyUp={keyUpSearch}
                                    placeholder="Search..."
                                    type="text"
                                    className="focus:outline-none w-full h-full ml-2 bg-transparent"
                                />
                                <BiSearchAlt2 size={`1.8em`} color={'#DADADA'} />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item sm={10} lg={9} className={`${styles.UserHomeContainer}`}>
                    <Grid container direction="row" justifyContent="space-between" spacing={0}>
                        <Grid item sm={6} lg={8} xl={8}></Grid>

                        <Grid item sm={6} lg={4} xl={4} style={{ textAlign: 'end' }}>
                            {/*<FormControl
                                variant="outlined"
                                style={{ width: '50%' }}>
                                <Select
                                    native
                                    // value={state.age}
                                    // onChange={handleChange}
                                    className={styles.ReportsSelect}
                                    inputProps={{
                                        name: 'viewsReportDurations',
                                        id: 'outlined-age-native-simple'
                                    }}
                                    style={{
                                        color: '#283E59',
                                        fontSize: '18px',
                                        fontWeight: 600
                                    }}>
                                    {viewsReportDurations.map(
                                        (viewsReportDuration, index) => (
                                            <option
                                                className={styles.ReportsSelect}
                                                key={index}
                                                value={
                                                    viewsReportDuration.value
                                                }>
                                                {viewsReportDuration.label}
                                            </option>
                                        )
                                    )}
                                </Select>
                                            </FormControl>*/}
                        </Grid>

                        <Grid item sm={12} lg={12} style={{ marginTop: 10 }}>
                            <div style={{ height: 625, width: '100%' }} className={classes.root}>
                                <DataGrid
                                    rows={filtered ? [...filtered] : [...presentations]}
                                    columns={columns}
                                    rowHeight={75}
                                    // hideFooter={true}
                                    pageSize={100}
                                    loading={loading}
                                    // autoPageSize
                                    showCellRightBorder={false}
                                    showColumnRightBorder={false}
                                    disableSelectionOnClick
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Footer />
        </div>
    );
};

export default UserDashboard;
