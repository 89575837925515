import React from 'react';
import Button from '@material-ui/core/Button';
import ColorSet from '../ColorSet/ColorSet';
import styles from './PrimaryButton.module.css';

const PrimaryButton = (props) => {
    let classes = [styles.Button, props.className];

    let colorSet = { ...ColorSet.primaryButton };

    if (props.variant === 'outlined') {
    }

    return (
        <Button
            {...props}
            style={{ ...colorSet, ...props.style }}
            className={classes.join(' ')}
            variant={props.variant || 'contained'}>
            {props.children}
        </Button>
    );
};

export default PrimaryButton;
