import React, { useEffect, useRef, useState } from 'react';
import styles from './Certificate.module.css';
import LogoImage from 'images/certificate/logo.png';
import jsPDF from 'jspdf';
import Topbar from 'components/headers/Topbar/Topbar.js';
import Footer from 'components/footers/UserFooter.js';
import * as htmlToImage from 'html-to-image';
import { RingLoader } from 'components/shared';
import { useDispatch, useSelector } from 'react-redux';
import { getCertificateForUserPresentationReset } from 'state/actions/user/user';
import { useUser } from 'util/auth.js';

const Certificate = (props) => {
    const certificateForPresentationState = useSelector(
        (state) => state.user.certificateForPresentation
    );
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(getCertificateForUserPresentationReset());
        };
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { user } = useUser();
    const [orgLogo, setOrgLogo] = useState(null);
    const [providerInfo, setProviderInfo] = useState(null);

    useEffect(() => {
        if (user) {
            console.log(user);
            if (user.attributes && user.attributes.zoneinfo) {
                let newLogo = `${process.env.REACT_APP_PROFILE_IMAGES_URL}/org-info/${user.attributes.zoneinfo}/logo`;
                setOrgLogo(newLogo);

                //TODO: implement this in presentation
                if (user.attributes.zoneinfo === 'bergenbio') {
                    setProviderInfo('KLIFO');
                }
            }
        }
    }, []);

    console.log(certificateForPresentationState);

    const a4ContainerRef = useRef(null);
    const options = {
        orientation: 'landscape',
        format: 'a4',
        unit: 'cm'
    };
    const [isDownloading, setIsDownloading] = useState(false);

    const downloadCertificate = () => {
        setIsDownloading(true);
        htmlToImage
            .toPng(a4ContainerRef.current)
            .then(function (dataUrl) {
                const pdf = new jsPDF(options);
                pdf.addImage(dataUrl, 'PNG', 0, 0, 0, 0);
                pdf.save('Certificate-of-completion.pdf');
                setIsDownloading(false);
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
                setIsDownloading(false);
            });
    };

    const printCertificate = async () => {
        setIsDownloading(true);
        htmlToImage
            .toPng(a4ContainerRef.current)
            .then(function (dataUrl) {
                const pdf = new jsPDF(options);
                pdf.addImage(dataUrl, 'PNG', 0, 0, 0, 0);
                pdf.autoPrint({ variant: 'non-conform' });
                window.open(pdf.output('bloburl'), '_blank');
                setIsDownloading(false);
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
                setIsDownloading(false);
            });
    };

    let containerUI = (
        <h1>
            No Certificate data available. Go to the dashboard page and view
            certificate.
        </h1>
    );

    if (certificateForPresentationState.data) {
        const { name, title, presentationDurationInHours, completionDate } =
            certificateForPresentationState.data;
        containerUI = (
            <>
                <div
                    ref={a4ContainerRef}
                    id="cert"
                    className={`${styles.Container} flex flex-col`}>
                    <img
                        className={styles.Logo}
                        src={orgLogo || LogoImage}
                        alt="Beaker Health Logo"
                    />

                    <div className={styles.CertificateContent}>
                        <h1>CERTIFICATE OF COMPLETION</h1>
                        <p className={styles.AwardedTo}>
                            This certificate is awarded to
                        </p>
                        <p className={styles.NameOfAwardee}>{name}</p>
                        <p className={styles.SuccessFullyCompleted}>
                            For Successfully Completing:
                        </p>
                        <p className={styles.AwardedFor}>{title}</p>
                        <p className={styles.ProvidersDetail}>
                            Completion Date: {completionDate}
                        </p>
                        <p className={styles.ProvidersDetail}>
                            {providerInfo ? (
                                <span>Provider's Name: {providerInfo}</span>
                            ) : (
                                <span>
                                    Provider's Name: Beaker Health, Inc
                                    <br />
                                    {presentationDurationInHours} of hours based
                                    on presentation (contact hours)
                                    {/*<br />
                                    Provider approved by the california board of
                                    registered nursing
                                    <br />
                                    Provider #: XXXXX for{' '}
                                    {presentationDurationInHours} of hours based
                            on presentation (contact hours)*/}
                                </span>
                            )}
                        </p>
                    </div>
                </div>

                {!isDownloading ? (
                    <div className="justify-center w-full mt-5 text-center">
                        <button
                            onClick={printCertificate}
                            className={`inline-block rounded py-3 sm:px-32 font-bold text-lg text-white mr-10 ${styles.Button} ${styles.ButtonActive}`}>
                            Print
                        </button>
                        <button
                            onClick={downloadCertificate}
                            className={`inline-block rounded py-3 px-24 font-bold text-lg text-white ${styles.Button} ${styles.ButtonActive}`}>
                            Download
                        </button>
                    </div>
                ) : (
                    <RingLoader />
                )}
            </>
        );
    }

    return (
        <>
            <Topbar cmsEnabled={false} />

            <div className={`flex flex-col justify-center items-center`}>
                {containerUI}
            </div>

            <Footer />
        </>
    );
};

export default Certificate;
