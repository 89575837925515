import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { MdAccountCircle } from 'react-icons/md';
import { useCMS } from 'tinacms';
import { useUser } from 'util/auth.js';
import { ClickAwayListener } from '@material-ui/core';
import _ from 'lodash';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import styles from './Topbar.module.css';

const ProfileImage = ({ user }) => {
    let userImg = '';
    const [errorImg, setErrorImg] = useState(false);
    const profileImageRef = useRef(null);
    const profileImageBaseURL = (username) =>
        `${
            process.env.REACT_APP_PROFILE_IMAGES_URL
        }/users/${username}/picture/profile.jpg?${new Date().getTime()}`;
    const userProfileImageState = useSelector((state) => state.user.userProfileImage);
    const userProfileState = useSelector((state) => state.user.userProfile);

    useEffect(() => {
        if (userProfileImageState.success && userProfileState.success) {
            let reader = new FileReader();
            reader.onload = (e) => {
                if (
                    !profileImageRef ||
                    !profileImageRef.current ||
                    !profileImageRef.current.src ||
                    errorImg
                ) {
                    setErrorImg(false);
                } else {
                    profileImageRef.current.src = reader.result;
                }
            };
            if (userProfileImageState.data) {
                reader.readAsDataURL(userProfileImageState.data);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userProfileImageState]);

    if (user.username) {
        userImg = profileImageBaseURL(user.username);
    }

    const imageError = (evt) => {
        if (!errorImg) {
            setErrorImg(true);
        }
    };

    return (
        <>
            {errorImg ? (
                <MdAccountCircle color="F9B041" size={'3em'} />
            ) : (
                <img
                    className={`object-cover w-full h-full ${styles.ProfileImage}`}
                    src={userImg}
                    ref={profileImageRef}
                    onError={imageError}
                    alt="Your avatar"></img>
            )}
        </>
    );
};

const ProfileCard = (props) => {
    const { userInfo } = props;
    const [showDropdown, setShowDropdown] = useState(false);

    const toggleDropdown = (evt) => {
        setShowDropdown(!showDropdown);
    };

    const onClickAwayHandler = () => {
        setShowDropdown(false);
    };

    return (
        <ClickAwayListener onClickAway={onClickAwayHandler}>
            <div className="relative">
                <button
                    onClick={toggleDropdown}
                    className="flex-column focus:outline-none flex items-center justify-center w-12 h-12 overflow-hidden rounded-full outline-none">
                    <ProfileImage user={userInfo} />
                </button>
                {showDropdown ? <ProfileDropdown {...props} /> : null}
            </div>
        </ClickAwayListener>
    );
};

const ProfileDropdown = (props) => {
    const { logout, isAdmin, isPresenter } = props;

    return (
        <div className="absolute right-0 z-10 w-48 mt-2 overflow-hidden bg-white rounded-md shadow-xl">
            <Link
                to="/user/profile"
                className="hover:bg-blue hover:text-white block px-4 py-2 text-sm text-gray-700">
                Profile
            </Link>
            {isPresenter() || isAdmin() ? (
                <a
                    href={process.env.REACT_APP_PRESENTER_URL}
                    className="hover:bg-blue hover:text-white block px-4 py-2 text-sm text-gray-700">
                    Educator
                </a>
            ) : null}
            {isAdmin() ? (
                <a
                    href="/admin"
                    className="hover:bg-blue hover:text-white block px-4 py-2 text-sm text-gray-700">
                    Admin
                </a>
            ) : null}
            {/* {isAdmin() && props.cmsEnabled ? <CmsToggle /> : null} */}
            <a
                href="/"
                onClick={async () => {
                    await logout();
                }}
                className="hover:bg-blue hover:text-white block px-4 py-2 text-sm text-gray-700">
                Logout
            </a>
        </div>
    );
};

const Toolbar = (props) => {
    const { user, isAdmin, isPresenter, logout } = useUser();
    const [userInfo, setUserInfo] = useState(null);
    const [orgLogo, setOrgLogo] = useState(null);
    const [logoLink, setLogoLink] = useState('/');

    useEffect(() => {
        if (user) {
            if (user.attributes && user.attributes.zoneinfo) {
                let newLogo = `${process.env.REACT_APP_PROFILE_IMAGES_URL}/org-info/${user.attributes.zoneinfo}/logo`;
                setOrgLogo(newLogo);
            }
        } else {
            if (props.orgName) {
                let newLogo = `${process.env.REACT_APP_PROFILE_IMAGES_URL}/org-info/${props.orgName}/logo`;
                setOrgLogo(newLogo);
                setLogoLink(`/login/${props.orgName}`);
            }
        }
    }, []);

    useEffect(() => {
        if (!_.isEqual(user, userInfo)) {
            setUserInfo(user);
        }
    }, [user]);

    const class1 = 'flex justify-between items-center w-full lg:px-16 sm:px-4 py-4';
    const class2 = 'flex justify-between items-center container mx-auto -lg:px-8 -md:px-4 py-8';

    let contentUI = (
        <Fragment>
            <ul className="text-blue items-left flex items-center space-x-10">
                {userInfo ? (
                    <>
                        <Link to="/user/home">
                            <li className="hover:underline font-semibold cursor-pointer">Search</li>
                        </Link>
                        <Link to="/user/dashboard">
                            <li className="hover:underline font-semibold cursor-pointer">
                                Dashboard
                            </li>
                        </Link>
                    </>
                ) : null}
            </ul>
            <div className="flex items-center ml-5 space-x-10">
                {userInfo ? (
                    <ProfileCard
                        {...props}
                        logout={logout}
                        isAdmin={isAdmin}
                        userInfo={userInfo}
                        isPresenter={isPresenter}
                    />
                ) : (
                    <>
                        <Link to="/login">
                            <button className="hover:shadow-xl focus:outline-none text-blue px-8 py-2 font-semibold bg-white rounded shadow">
                                Login
                            </button>
                        </Link>
                        <Link to="/pricing">
                            <button className="hover:shadow-xl focus:outline-none bg-yellow px-8 py-2 font-semibold text-white rounded shadow">
                                Sign Up
                            </button>
                        </Link>
                    </>
                )}
            </div>
        </Fragment>
    );

    let wrappedContentUI = <div className="flex">{contentUI}</div>;

    if (!userInfo) {
        wrappedContentUI = <>{contentUI}</>;
    }

    return (
        <div className={userInfo ? class1 : class2}>
            <Link to={logoLink}>
                {orgLogo ? (
                    <img src={orgLogo} alt="headerlogo" className="w-16" />
                ) : (
                    <img
                        src={require('../../../pages/assets/img/headerlogo.png')}
                        alt="headerlogo"
                        className="w-32"
                    />
                )}
            </Link>

            {wrappedContentUI}
        </div>
    );
};

export default Toolbar;
