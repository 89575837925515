import 'tailwindcss/dist/base.css';
import 'tailwindcss/dist/components.css';
import 'tailwindcss/dist/utilities.css';
import 'styles/globalStyles.css';
import 'styles/main.css';
import React from 'react';
import { css } from 'styled-components/macro'; //eslint-disable-line

//import HomePage from "pages/HomePage.js";
/* Inner Pages */
import LoginPage from 'pages/Login.js';
import SignupPage from 'pages/Signup.js';
import SignupCompletePage from 'pages/Signup-Complete.js';
import ResendPage from 'pages/ResendVerification.js';
import ForgotPasswordPage from 'pages/ForgotPassword.js';
import ResetPasswordPage from 'pages/ResetPassword.js';
import UserHome from 'pages/UserHome';
import Dashboard from 'pages/UserDashboard';
import Admin from 'pages/Admin';
import Presentation from 'pages/PresentationPage/PresentationPage.jsx';
import ViewPresentation from 'pages/PresentationPage/ViewPresentation/ViewPresentation';
import Certificate from 'pages/Certificate/Certificate';
import UserProfile from 'pages/UserProfile';
import PresenterSearch from 'pages/PresenterSearch';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';
import { TinaProvider, TinaCMS, useCMS } from 'tinacms';
import { useUser } from 'util/auth.js';
import { RingLoader } from 'components/shared';

function PrivateRoute({ children, ...rest }) {
    const { user, isLoaded } = useUser();
    return (
        <Route
            {...rest}
            render={({ location }) =>
                !isLoaded ? (
                    <div className={`justify-center text-center my-20`}>
                        <RingLoader />
                    </div>
                ) : user ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}

export default function App() {
    const cms = new TinaCMS({
        enabled: false,
        sidebar: true
    });
    return (
        <Router>
            <Switch>
                <Route path="/login/:org">
                    <LoginPage />
                </Route>
                <Route exact path="/login">
                    <LoginPage />
                </Route>
                <Route path="/signup/:org">
                    <SignupPage />
                </Route>
                <Route exact path="/signup">
                    <SignupPage />
                </Route>
                <Route path="/signup-complete">
                    <SignupCompletePage />
                </Route>
                <Route path="/resend">
                    <ResendPage />
                </Route>
                <Route path="/forgot-password">
                    <ForgotPasswordPage />
                </Route>
                <Route path="/reset-password">
                    <ResetPasswordPage />
                </Route>
                <PrivateRoute path="/user/home">
                    <TinaProvider cms={cms}>
                        <UserHome />
                    </TinaProvider>
                </PrivateRoute>
                <Route path="/presenter/:name">
                    <TinaProvider cms={cms}>
                        <PresenterSearch />
                    </TinaProvider>
                </Route>
                <PrivateRoute path="/user/dashboard">
                    <TinaProvider cms={cms}>
                        <Dashboard />
                    </TinaProvider>
                </PrivateRoute>
                <PrivateRoute path="/user/profile">
                    <UserProfile />
                </PrivateRoute>
                <PrivateRoute path="/admin">
                    <Admin />
                </PrivateRoute>
                <PrivateRoute path="/course/:courseId/module/view/:id">
                    <ViewPresentation />
                </PrivateRoute>
                <PrivateRoute exact path="/presentation/view/:id">
                    <ViewPresentation />
                </PrivateRoute>
                <Route exact path="/presentation/:orgName/:id">
                    <Presentation />
                </Route>
                <Route exact path="/presentation/:id">
                    <Presentation />
                </Route>
                <PrivateRoute path="/certificate">
                    <Certificate></Certificate>
                </PrivateRoute>
                <PrivateRoute path="/">
                    <TinaProvider cms={cms}>
                        <UserHome />
                    </TinaProvider>
                </PrivateRoute>
            </Switch>
        </Router>
    );
}
