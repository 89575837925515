import React, { Component, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Topbar from 'components/headers/Topbar/Topbar';
import Footer from 'components/footers/UserFooter';
import ReactTooltip from 'react-tooltip';
import { onSaveUserProfile } from '../../state/actions/user/user';
import { getUserProfile } from '../../state/actions/user/user';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { RingLoader } from 'components/shared';
import { MdAccountCircle } from 'react-icons/md';
import { WarningAlert } from 'components/shared/UI/Theme';
import styles from './UserProfile.module.css';

const useStyles = {
    root: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid rgba(149, 170, 201, 1)'
        }
    },
    input: {
        color: '#283E59',
        fontSize: '1rem',
        fontWeight: '600',
        fontStyle: 'normal'
    },
    inputLabel: {
        fontWeight: 'normal',
        fontSize: '20px',
        color: '#95AAC9'
    }
};

const ProfileImage = React.memo(({ username }) => {
    let userImg = '';
    const [errorImg, setErrorImg] = useState(false);
    const profileImageBaseURL = (username) =>
        `${
            process.env.REACT_APP_PROFILE_IMAGES_URL
        }/users/${username}/picture/profile.jpg?${new Date().getTime()}`;

    if (username) {
        userImg = profileImageBaseURL(username);
    }
    function imageError(evt) {
        setErrorImg(true);
    }
    return (
        <>
            {errorImg ? (
                <MdAccountCircle color="F9B041" size={'3em'} />
            ) : (
                <img
                    className={`profile-image ${styles.ProfileImage}`}
                    src={userImg}
                    onError={imageError}
                    alt="Your avatar"></img>
            )}
        </>
    );
});

class UserProfile extends Component {
    constructor() {
        super();
        this.onChange = this.onChange.bind(this);
        this.state = {
            userProfile: {
                title: '',
                credentials: '',
                profileImage: '',
                first_name: '',
                last_name: '',
                occupation: '',
                language: '',
                linkedin_url: '',
                twitter_url: '',
                fb_url: '',
                website_url: '',
                about_me: ''
            },
            userProfilePreview: {
                src: '',
                fileName: ''
            },
            username: ''
        };
    }

    componentDidMount() {
        this.props.getUserProfile();
        Auth.currentAuthenticatedUser({
            bypassCache: false
        })
            .then((user) => {
                if (user.username) {
                    this.setState({ username: user.username });
                }
            })
            .catch((err) => console.log(err));
    }

    componentDidUpdate(prevProps, prevState) {
        const userProfile = this.props.userProfileState.data;
        if (!_.isEqual(prevProps.userProfileState.data, userProfile)) {
            this.setState({ userProfile });
        }
    }

    onChange(e) {
        const fileName = e.target.files[0].name;
        let reader = new FileReader();
        reader.onload = (e) => {
            this.setState({
                userProfilePreview: {
                    src: reader.result,
                    fileName: fileName
                }
            });
        };
        reader.readAsDataURL(e.target.files[0]);

        let userProfile = _.cloneDeep(this.state.userProfile);
        userProfile.profileImage = e.target.files[0];
        this.setState({ userProfile });
    }

    onInputChangeHandler = (value, fieldName) => {
        let userProfile = _.cloneDeep(this.state.userProfile);
        userProfile[fieldName] = value;
        this.setState({ userProfile });
    };

    onSubmitUserProfile = () => {
        const userProfile = _.cloneDeep(this.state.userProfile);
        const userProfileImage = userProfile.profileImage;
        delete userProfile.profileImage;
        this.props.onSaveUserProfile(userProfileImage, userProfile);
    };

    render() {
        const materialStyles = this.props.classes;
        const { userProfile } = this.state;
        const { userProfileState, userProfileImageState } = this.props;

        let profileImageUI = null;
        if (this.state.src === '' && !this.state.username) {
            profileImageUI = (
                <i
                    className="fa fa-user-circle profile-icon "
                    aria-hidden="true"
                />
            );
        } else if (this.state.username && !this.state.userProfilePreview.src) {
            profileImageUI = <ProfileImage username={this.state.username} />;
        } else {
            profileImageUI = (
                <img
                    className={`profile-image ${styles.ProfileImage}`}
                    src={this.state.userProfilePreview.src}
                    alt="Avatar"
                />
            );
        }

        let containerUI = (
            <div className="App">
                <Topbar cmsEnabled={false} />
                <Grid container justify="center">
                    <Grid item xs={8} lg={8}>
                        <Grid container className={styles.Container}>
                            {userProfileState.error ? (
                                <Grid item sm={12} md={12} lg={12}>
                                    <div
                                        className={`${styles.UserProfileErrorContainer}`}>
                                        <WarningAlert
                                            message={
                                                'Failed to update user profile.'
                                            }
                                        />
                                    </div>
                                </Grid>
                            ) : null}

                            {userProfileImageState.error ? (
                                <Grid item sm={12} md={12} lg={12}>
                                    <div
                                        className={`${styles.UserProfileImageErrorContainer}`}>
                                        <WarningAlert
                                            message={
                                                'Error occured while uploading user profile image.'
                                            }
                                        />
                                    </div>
                                </Grid>
                            ) : null}

                            <Grid item xs={12}>
                                <h1 className={styles.Title}>Profile</h1>
                            </Grid>

                            <Grid item xs={12}>
                                <span className={styles.SubTitle}>
                                    Personal Information
                                </span>
                                <span className={styles.SubTitleInfo}>
                                    This information is public
                                </span>
                            </Grid>

                            <Grid item xs={2}>
                                <div className={styles.FieldContainer}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        placeholder="Title"
                                        className=""
                                        value={userProfile.title || ''}
                                        onChange={(event) =>
                                            this.onInputChangeHandler(
                                                event.target.value,
                                                'title'
                                            )
                                        }
                                        fullWidth
                                        classes={{
                                            root: materialStyles.root
                                        }}
                                        InputProps={{
                                            className: materialStyles.input
                                        }}
                                        InputLabelProps={{
                                            className: materialStyles.inputLabel
                                        }}
                                    />
                                    <span className={styles.Label}>
                                        Honorific
                                    </span>
                                </div>
                            </Grid>
                            <Grid item xs={5}>
                                <div className={styles.FieldContainer}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        placeholder="Legal or Nickname"
                                        className=""
                                        value={userProfile.first_name || ''}
                                        onChange={(event) =>
                                            this.onInputChangeHandler(
                                                event.target.value,
                                                'first_name'
                                            )
                                        }
                                        fullWidth
                                        classes={{
                                            root: materialStyles.root
                                        }}
                                        InputProps={{
                                            className: materialStyles.input
                                        }}
                                        InputLabelProps={{
                                            className: materialStyles.inputLabel
                                        }}
                                    />
                                    <span className={styles.Label}>
                                        First Name
                                    </span>
                                </div>
                            </Grid>
                            <Grid item xs={5}>
                                <div className={styles.FieldContainer}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        placeholder="Legal Name"
                                        className=""
                                        value={userProfile.last_name || ''}
                                        onChange={(event) =>
                                            this.onInputChangeHandler(
                                                event.target.value,
                                                'last_name'
                                            )
                                        }
                                        classes={{
                                            root: materialStyles.root
                                        }}
                                        InputProps={{
                                            className: materialStyles.input
                                        }}
                                        InputLabelProps={{
                                            className: materialStyles.inputLabel
                                        }}
                                    />
                                    <span className={styles.Label}>
                                        Last Name
                                    </span>
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div className={styles.FieldContainer}>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Type here..."
                                        className=""
                                        value={userProfile.occupation || ''}
                                        onChange={(event) =>
                                            this.onInputChangeHandler(
                                                event.target.value,
                                                'occupation'
                                            )
                                        }
                                        classes={{
                                            root: materialStyles.root
                                        }}
                                        InputProps={{
                                            className: materialStyles.input
                                        }}
                                        InputLabelProps={{
                                            className: materialStyles.inputLabel
                                        }}
                                    />
                                    <span className={styles.Label}>
                                        Occupation
                                    </span>
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div className={styles.FieldContainer}>
                                    <TextField
                                        variant="outlined"
                                        placeholder="You worked for it. Show it off."
                                        className=""
                                        value={userProfile.credentials || ''}
                                        onChange={(event) =>
                                            this.onInputChangeHandler(
                                                event.target.value,
                                                'credentials'
                                            )
                                        }
                                        classes={{
                                            root: materialStyles.root
                                        }}
                                        InputProps={{
                                            className: materialStyles.input
                                        }}
                                        InputLabelProps={{
                                            className: materialStyles.inputLabel
                                        }}
                                    />
                                    <span className={styles.Label}>
                                        Credentials{' '}
                                        <InfoOutlinedIcon
                                            style={{ marginLeft: '10px' }}
                                            data-tip
                                            data-for="credentials-tip"
                                        />
                                    </span>

                                    <ReactTooltip
                                        id="credentials-tip"
                                        place="right"
                                        effect="solid"
                                        backgroundColor="white"
                                        textColor="#3C7BB3"
                                        border
                                        borderColor="#A3C0DB">
                                        Seperate each credentials with a comma.
                                        <br />
                                        (Example: MSN, RN, FNP-BC, etc.)
                                    </ReactTooltip>
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div className={styles.FieldContainer}>
                                    <div
                                        className={
                                            styles.UploadProfileImageContainer
                                        }>
                                        {profileImageUI}

                                        <label
                                            htmlFor="file-upload"
                                            className={`${styles.UploadImageLabel}`}>
                                            Upload image
                                            <input
                                                type="file"
                                                style={{ display: 'none' }}
                                                accept="image/*"
                                                id="file-upload"
                                                onChange={this.onChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div className={styles.FieldContainer}>
                                    <TextField
                                        multiline
                                        rows="8"
                                        cols="50"
                                        id="w3review"
                                        variant="outlined"
                                        placeholder="Brag about yourself. This will be shown on your educator page."
                                        className=""
                                        value={userProfile.about_me || ''}
                                        onChange={(event) =>
                                            this.onInputChangeHandler(
                                                event.target.value,
                                                'about_me'
                                            )
                                        }
                                        classes={{
                                            root: materialStyles.root
                                        }}
                                        InputProps={{
                                            className: materialStyles.input
                                        }}
                                        InputLabelProps={{
                                            className: materialStyles.inputLabel
                                        }}
                                    />
                                    <span className={styles.Label}>
                                        About me
                                    </span>
                                </div>
                            </Grid>

                            <Grid item xs={12} style={{ marginTop: '20px' }}>
                                <span className={styles.SubTitle}>
                                    Social media information
                                </span>
                                <span className={styles.SubTitleInfo}>
                                    This information is not public but helps
                                    with search rankings
                                </span>
                            </Grid>

                            <Grid item xs={6}>
                                <div className={styles.FieldContainer}>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Vouch for it"
                                        className=""
                                        value={userProfile.linkedin_url || ''}
                                        onChange={(event) =>
                                            this.onInputChangeHandler(
                                                event.target.value,
                                                'linkedin_url'
                                            )
                                        }
                                        classes={{
                                            root: materialStyles.root
                                        }}
                                        InputProps={{
                                            className: materialStyles.input
                                        }}
                                        InputLabelProps={{
                                            className: materialStyles.inputLabel
                                        }}
                                    />
                                    <span className={styles.Label}>
                                        LinkedIn
                                    </span>
                                </div>
                            </Grid>

                            <Grid item xs={6}>
                                <div className={styles.FieldContainer}>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Do you tweet?"
                                        className=""
                                        value={userProfile.twitter_url || ''}
                                        onChange={(event) =>
                                            this.onInputChangeHandler(
                                                event.target.value,
                                                'twitter_url'
                                            )
                                        }
                                        classes={{
                                            root: materialStyles.root
                                        }}
                                        InputProps={{
                                            className: materialStyles.input
                                        }}
                                        InputLabelProps={{
                                            className: materialStyles.inputLabel
                                        }}
                                    />
                                    <span className={styles.Label}>
                                        Twitter
                                    </span>
                                </div>
                            </Grid>

                            <Grid item xs={6}>
                                <div className={styles.FieldContainer}>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Social Proof?"
                                        className=""
                                        value={userProfile.fb_url || ''}
                                        onChange={(event) =>
                                            this.onInputChangeHandler(
                                                event.target.value,
                                                'fb_url'
                                            )
                                        }
                                        classes={{
                                            root: materialStyles.root
                                        }}
                                        InputProps={{
                                            className: materialStyles.input
                                        }}
                                        InputLabelProps={{
                                            className: materialStyles.inputLabel
                                        }}
                                    />
                                    <span className={styles.Label}>
                                        Facebook
                                    </span>
                                </div>
                            </Grid>

                            <Grid item xs={6}>
                                <div className={styles.FieldContainer}>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Other digital avenues?"
                                        className=""
                                        value={userProfile.website_url || ''}
                                        onChange={(event) =>
                                            this.onInputChangeHandler(
                                                event.target.value,
                                                'website_url'
                                            )
                                        }
                                        classes={{
                                            root: materialStyles.root
                                        }}
                                        InputProps={{
                                            className: materialStyles.input
                                        }}
                                        InputLabelProps={{
                                            className: materialStyles.inputLabel
                                        }}
                                    />
                                    <span className={styles.Label}>
                                        Website
                                    </span>
                                </div>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                className={styles.SaveActionContainer}>
                                <div className={styles.SaveAction}>
                                    <button
                                        className={`px-8 py-3 font-bold rounded focus:shadow-outline focus:outline-none transition duration-300 w-full ${styles.SaveButton}`}
                                        name={'Save'}
                                        onClick={() => {
                                            this.onSubmitUserProfile();
                                        }}
                                        disabled={
                                            userProfile.first_name !== '' &&
                                            userProfile.last_name !== '' &&
                                            userProfile.occupation !== ''
                                                ? false
                                                : true
                                        }>
                                        Save
                                    </button>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Footer />
            </div>
        );

        if (this.props.userProfileState.loading) {
            containerUI = (
                <div className="flex justify-center mt-16">
                    <RingLoader />
                </div>
            );
        }

        return containerUI;
    }
}

function mapStateToProps(state) {
    return {
        userProfileState: state.user.userProfile,
        userProfileImageState: state.user.userProfileImage
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            onSaveUserProfile,
            getUserProfile
        },
        dispatch
    );
}

export default withStyles(useStyles)(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(UserProfile))
);
