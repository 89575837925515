import React, { useState } from 'react';
import Topbar from 'components/headers/Topbar/Topbar';
import Footer from 'components/footers/UserFooter';
import AdminToolBar from './AdminToolBar';
import Grid from '@material-ui/core/Grid';
import Presentations from './Presentations';
import Members from './Members';
import Invites from './Invites';
import Compliance from './Compliance';
import { useHistory } from 'react-router-dom';

const AdminPages = {
    Presentations: 'presentations',
    Members: 'members',
    Invites: 'invites',
    Compliance: 'compliance'
};

const renderSwitch = (tab) => {
    switch (tab) {
        case AdminPages.Presentations:
            return <Presentations />;

        case AdminPages.Members:
            return <Members />;

        case AdminPages.Invites:
            return <Invites />;

        case AdminPages.Compliance:
            return <Compliance />;

        default:
            return <Presentations />;
    }
};

const Admin = (props) => {
    const [selectedTab, setSelectedTab] = useState('presentations');
    const history = useHistory();

    const onTabSelect = (tab) => {
        setSelectedTab(tab);
        history.push({
            pathname: '/admin',
            search: '?' + new URLSearchParams({ tab }).toString()
        });
    };

    return (
        <div className="App">
            <Topbar />

            <Grid container justifyContent="center">
                <Grid item xs={8}>
                    <AdminToolBar onTabSelect={onTabSelect} />
                </Grid>

                <Grid item xs={8}>
                    {renderSwitch(selectedTab)}
                </Grid>
            </Grid>

            <Footer />
        </div>
    );
};

export default Admin;
