import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Topbar from 'components/headers/Topbar/Topbar.js';
import Footer from 'components/footers/UserFooter.js';
import styles from './PresenterSearch.module.css';
import { withStyles } from '@material-ui/core/styles';
import { RingLoader } from 'components/shared/UI';
import Card from './PresentationCard';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';

const useStyles = {
    root: {
        '& .MuiOutlinedInput-input': {
            padding: '10.5px 14px'
        }
    }
};

const Cards = ({ presentations, searchTerm }) => {
    if (presentations.length === 0) {
        return (
            <div className="sm:px-4 lg:px-0 container flex flex-col items-center justify-center max-w-screen-lg mx-auto">
                <img
                    className={`my-12`}
                    src={require('../../images/sample/presentation/Standing.png')}
                    alt="No Result User"
                />

                <p className={`${styles.NoResultMessage}`}>
                    No results for "{searchTerm}" Try <br /> searching for
                    something else
                </p>
            </div>
        );
    }

    const presCards = presentations.map((presentation, i) => {
        console.log(presentation);
        let source = presentation.document;
        if (source.title) {
            let keywords = [];
            if (source.keywords) {
                keywords = source.keywords;
            }
            let textKeywords = [];
            if (keywords.length > 0) {
                let limit = keywords.length;
                if (limit > 3) {
                    limit = 3;
                }
                for (let i = 0; i < limit; i++) {
                    textKeywords.push(keywords[i]);
                }
            }

            return (
                <Link
                    key={i}
                    to={{
                        pathname: '/presentation/' + source.id,
                        state: { presentation: source }
                    }}>
                    <Card
                        icon={require('../assets/img/iconmovie.png')}
                        source={source}
                        title={source.title}
                        keywords={textKeywords}
                        description={source.description}
                    />
                </Link>
            );
        }
    });

    return (
        <div className="w-full lg:grid-cols-3 md:grid md:grid-cols-2 gap-x-4">
            {presCards}
        </div>
    );
};

const apigatewayendpoint = process.env.REACT_APP_API_ENDPOINT_URL;

const UserHome = (props) => {
    const [presentations, setPresentations] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('*');

    let location = useLocation();
    const { name: presenterName } = useParams();

    function search(value) {
        setSearchTerm(value);
        if (value.trim() === '') {
            value = '*';
        }
        if (value.length > 2 || value === '*') {
            if (!isLoading) {
                setIsLoading(true);

                let paramValue = {
                    q: value
                };

                axios
                    .get(apigatewayendpoint + '/searchv1', {
                        params: paramValue
                    })
                    .then(function (response) {
                        setPresentations(response.data);
                        setIsLoading(false);
                        console.log(response.data);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        }
    }

    useEffect(() => {
        console.log(presenterName, location);
        if (presenterName) {
            const presenterToSearch = presenterName.split('-').join(' ');
            search(presenterToSearch);
        }
    }, []);

    return (
        <div className={`App`}>
            <Topbar cmsEnabled={true} />

            <div className={`${styles.PresenterSearchContainer}`}>
                <div
                    className={`container mx-auto sm:px-4 lg:px-0 flex items-center mt-8 place-content-stretch ${styles.UserSectionDivider}`}>
                    {isLoading ? (
                        <div className="sm:px-4 lg:px-0 container flex flex-col items-center justify-center max-w-screen-lg mx-auto mt-12">
                            <RingLoader />
                            <p className="items-center"></p>
                        </div>
                    ) : (
                        <Cards
                            presentations={presentations}
                            searchTerm={searchTerm}
                        />
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default withStyles(useStyles)(UserHome);
