import React from 'react';
import { FaFacebookF, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import styles from './UserFooter.module.css';

export default () => {
    return (
        <div
            className={`flex flex-col-reverse sm:flex-row justify-between items-center container mx-auto -lg:px-4 -md:px-4 py-4 ${styles.CopyRightColor}`}>
            <p className="mt-6 mb-4 text-base font-normal">
                Copyright &copy; 2024 Beaker Health. Inc
            </p>

            <div className="flex">
                <ul className="flex items-center mr-10 space-x-5">
                    <Link to="/terms-conditions">
                        <li
                            className={`hover:underline cursor-pointer font-semibold text-base ${styles.LinkColor}`}>
                            Terms & Conditions
                        </li>
                    </Link>
                    <Link to="/privacy">
                        <li
                            className={`hover:underline cursor-pointer font-semibold text-base ${styles.LinkColor}`}>
                            Privacy Policy
                        </li>
                    </Link>
                </ul>
                <div className={`flex items-center justify-center space-x-5 ${styles.IconColor}`}>
                    <FaFacebookF className="text-2xl" />
                    <FaTwitter className="text-2xl" />
                    <FaLinkedinIn className="text-2xl" />
                </div>
            </div>
        </div>
    );
};
