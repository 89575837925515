const toLocalWithTwoDigits = (number) => {
    return number.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
    });
}
const isNumberValueEmpty = (value) => {
    if (+value === 0 || (+value).toFixed(2) === 0.00 || Number.isNaN(+value)) {
        return true;
    }
    return false;
};

export { isNumberValueEmpty, toLocalWithTwoDigits };