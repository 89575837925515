import React from 'react';
import styles from './NoResult.module.css';

const NoResult = (props) => {
    return (
        <div className={`flex flex-col justify-center ${styles.Container}`}>

            <div className={`flex flex-col items-center mb-8`}>
                <span className={`block ${styles.Header}`}>404 Error</span>
                <span className={`block ${styles.Message}`}>{props.message}</span>
            </div>

            <div className={`flex flex-col items-center justify-center`}>
                <img src={require('../../../../images/sample/presentation/standing 17.png')} alt="Person Standing" />
            </div>
        </div>
    )
}

export default NoResult;