import React from 'react';
import { FaFacebookF, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export default () => {
    return (
        <div className="bg-blue -lg:px-8 -md:px-4 flex flex-col items-center justify-center py-10 text-white">
            <p>Beaker Health</p>
            <h1 className="text-yellow sm:text-3xl mb-2 text-lg font-bold">
                Empower Healthcare Professionals
            </h1>
            <ul className="flex items-center space-x-5">
                <Link to="/faq">
                    <li className="hover:underline font-semibold cursor-pointer">FAQ</li>
                </Link>
                <Link to="/about">
                    <li className="hover:underline font-semibold cursor-pointer">About</li>
                </Link>
                <Link to="/privacy">
                    <li className="hover:underline font-semibold cursor-pointer">Privacy</li>
                </Link>
                <Link to="/blog">
                    <li className="hover:underline font-semibold cursor-pointer">Blog</li>
                </Link>
                <Link to="/contact">
                    <li className="hover:underline font-semibold cursor-pointer">Contact</li>
                </Link>
            </ul>
            <p className="mt-6 mb-4 text-sm">&copy; 2024 Beaker health All Rights Reserved</p>
            <div className="flex items-center justify-center space-x-5">
                <FaFacebookF className="text-2xl" />
                <FaTwitter className="text-2xl" />
                <FaLinkedinIn className="text-2xl" />
            </div>
        </div>
    );
};
