import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { BiSearchAlt2 } from 'react-icons/bi';
import Topbar from 'components/headers/Topbar/Topbar.js';
import Footer from 'components/footers/UserFooter.js';
import { useForm, usePlugin } from 'tinacms';
import userHomeContent from 'content/userHomeContent.js';
import styles from './UserHome.module.css';
import { withStyles } from '@material-ui/core/styles';
import { RingLoader } from 'components/shared/UI';
import Card from './PresentationCard';
import { API } from 'aws-amplify';
import { useUser } from 'util/auth.js';

const useStyles = {
    root: {
        '& .MuiOutlinedInput-input': {
            padding: '10.5px 14px'
        }
    }
};

const NoSearchTermMessage = ({ searchTerm }) => {
    if (searchTerm === '*' || searchTerm === '') {
        return (
            <p className={`${styles.NoResultMessage}`}>
                No results found. Try <br /> searching for something else
            </p>
        );
    } else {
        return (
            <p className={`${styles.NoResultMessage}`}>
                No results for "{searchTerm}" Try <br /> searching for something else
            </p>
        );
    }
};

const Cards = ({ presentations, searchTerm }) => {
    if (presentations.length === 0) {
        return (
            <div className="sm:px-4 lg:px-0 container flex flex-col items-center justify-center max-w-screen-lg mx-auto">
                <img
                    className={`my-12`}
                    src={require('../../images/sample/presentation/Standing.png')}
                    alt="No Result User"
                />
                <NoSearchTermMessage searchTerm={searchTerm} />
            </div>
        );
    }

    const presCards = presentations.map((presentation, i) => {
        //console.log(presentation);
        let source = presentation.document;
        if (source.title) {
            let keywords = [];
            if (source.keywords) {
                keywords = source.keywords;
            }
            let textKeywords = [];
            if (keywords.length > 0) {
                let limit = keywords.length;
                if (limit > 3) {
                    limit = 3;
                }
                for (let i = 0; i < limit; i++) {
                    textKeywords.push(keywords[i]);
                }
            }

            return (
                <Link
                    key={i}
                    to={{
                        pathname: '/presentation/' + source.id,
                        state: { presentation: source }
                    }}>
                    <Card
                        icon={require('../assets/img/iconmovie.png')}
                        source={source}
                        title={source.title}
                        keywords={textKeywords}
                        description={source.description}
                    />
                </Link>
            );
        }
    });

    return <div className="lg:grid-cols-3 md:grid md:grid-cols-2 gap-x-4 w-full">{presCards}</div>;
};

const UserHome = (props) => {
    const [presentations, setPresentations] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [firstLoading, setFirstLoading] = useState(true);
    const [suggestion, setSuggestion] = useState(true);
    const [optionsState, setOptionsState] = useState('Other');
    const [searchTerm, setSearchTerm] = useState('*');
    const { user } = useUser();

    const formConfig = userHomeContent();
    const [editableData, form] = useForm(formConfig);
    usePlugin(form);

    const handleChangeOption = (event) => {
        setOptionsState(event.target.value);
        search(searchTerm, event.target.value);
    };

    const category = [
        { title: 'Adult Gerontology' },
        { title: 'Ambulatory Care' },
        { title: 'Anesthesia' },
        { title: 'Audiovisual Technology' },
        { title: 'Cardiac Care' },
        { title: 'Critical Care' },
        { title: 'Community public health' },
        { title: 'Disaster Preparedness' },
        { title: 'Education' },
        { title: 'Family and Individual Lifespan' },
        { title: 'General Practice' },
        { title: 'Home Health' },
        { title: 'Informatics' },
        { title: 'Moulage & Theatrics' },
        { title: 'Nurse Executive' },
        { title: 'Nursing Case Management' },
        { title: 'Nursing Professional Development' },
        { title: 'Operations & Management' },
        { title: 'Pain Management' },
        { title: 'Policy' },
        { title: 'Simulation' },
        { title: 'Other' }
    ];

    let categories = category.map((cat, i) => {
        return (
            <option key={i} value={cat.title}>
                {cat.title}
            </option>
        );
    });

    let timer = 0;
    function keyUpSearch(e) {
        e.persist();
        clearTimeout(timer);
        timer = setTimeout(() => {
            setSuggestion(false);
            if (optionsState !== 'Categories') {
                 if (e.target.value !== '') {
                    search(e.target.value, optionsState);
                } else {
                    search('*', optionsState);
                }
            } else {
                search(e.target.value);
            }
        }, 250);
    }

    function search(value, category) {
        if (!firstLoading) {
            setSearchTerm(value);
        }
        if (value.trim() === '') {
            value = '*';
        }
        if (value.length > 2 || value === '*') {
            if (!isLoading) {
                setIsLoading(true);

                let paramValue = {
                    q: value
                };

                if (category) {
                    paramValue.cat = category;
                }

                if (user.attributes) {
                    if (user.attributes.zoneinfo) {
                        paramValue.zoneinfo = user.attributes.zoneinfo;
                    }
                }

                const callParams = {
                    queryStringParameters: paramValue
                };

                API.get(process.env.REACT_APP_API_ENDPOINT_NAME, '/searchv1', callParams)
                    .then(function (response) {
                        //console.log(response);
                        setPresentations(response);
                        setIsLoading(false);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        }
    }

    if (firstLoading) {
        search('*', 'Other');
        setFirstLoading(false);
    }

    return (
        <div className={`App`}>
            <Topbar cmsEnabled={true} />

            <div className={`${styles.UserHomeContainer}`}>
                <div
                    className={`container mx-auto sm:px-4 lg:px-0 flex flex-col justify-center mt-12 ${styles.UserSectionDivider}`}>
                    <div className="sm:grid sm:grid-cols-2 justify-between w-full gap-10">
                        <span
                            className={`text-blue font-bold text-2xl mt-auto ${styles.PresentationListHeader}`}>
                            Search Modules
                        </span>
                    </div>
                    <div
                        className={`sm:grid justify-between mb-5 w-full grid-cols-2 gap-10 ${styles.FilterResults}`}>
                        <div
                            className={`bg-white flex items-center p-2 rounded-md my-4 w-full ${styles.SearchBar}`}>
                            <input
                                onKeyUp={keyUpSearch}
                                placeholder="Search..."
                                type="text"
                                style={{ color: '#01539D' }}
                                className="focus:outline-none w-full h-full ml-2 text-xl bg-transparent"
                            />
                            <BiSearchAlt2 className="mt-1 text-xl" color={'#DADADA'} />
                        </div>
                        <div>
                            <div className={`w-full ${styles.CategoriesSelectWrapper}`}>
                                <select
                                    value={optionsState}
                                    onChange={handleChangeOption}
                                    style={{ color: '#01539D' }}
                                    className={`bg-white flex items-center p-2 rounded-md my-4 w-full text-xl ${styles.CategoriesSelect}`}>
                                    {categories}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                {suggestion ? (
                    <div
                        className={`container mx-auto sm:px-4 lg:px-0 flex flex-col justify-center items-center max-w-screen-lg mt-6 ${styles.UserSectionDivider}`}>
                        <h2
                            className={`text-blue font-bold text-2xl mt-auto ${styles.PresentationListHeader}`}>
                            {editableData.suggestion_text}
                        </h2>
                    </div>
                ) : (
                    <div
                        className={`container mx-auto sm:px-4 lg:px-0 flex flex-col justify-center items-center max-w-screen-lg mt-6 ${styles.UserSectionDivider}`}>
                        <h2
                            className={`text-blue font-bold text-2xl mt-auto ${styles.PresentationListHeader}`}>
                            {searchTerm != ''
                                ? `Showing ${presentations.length} Results for "${searchTerm}"`
                                : 'Available Modules'}
                        </h2>
                    </div>
                )}

                <div
                    className={`container mx-auto sm:px-4 lg:px-0 flex items-center mt-8 place-content-stretch ${styles.UserSectionDivider}`}>
                    {isLoading ? (
                        <div className="sm:px-4 lg:px-0 container flex flex-col items-center justify-center max-w-screen-lg mx-auto mt-12">
                            <RingLoader />
                            <p className="items-center"></p>
                        </div>
                    ) : (
                        <Cards presentations={presentations} searchTerm={searchTerm} />
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default withStyles(useStyles)(UserHome);
