export const GET_PRESENTATION_START = 'GET_PRESENTATION_START';
export const GET_PRESENTATION_SUCCESS = 'GET_PRESENTATION_SUCCESS';
export const GET_PRESENTATION_FAIL = 'GET_PRESENTATION_FAIL';
export const GET_PRESENTATION_RESET = 'GET_PRESENTATION_RESET';
export const GET_PRESENTATION = 'GET_PRESENTATION';

export const GET_PRESENTATION_INFO_START = 'GET_PRESENTATION_INFO_START';
export const GET_PRESENTATION_INFO_SUCCESS = 'GET_PRESENTATION_INFO_SUCCESS';
export const GET_PRESENTATION_INFO_FAIL = 'GET_PRESENTATION_INFO_FAIL';
export const GET_PRESENTATION_INFO = 'GET_PRESENTATION_INFO';

export const SUBMIT_PRESENTATION_FEEDBACK_START =
    'SUBMIT_PRESENTATION_FEEDBACK_START';
export const SUBMIT_PRESENTATION_FEEDBACK_SUCCESS =
    'SUBMIT_PRESENTATION_FEEDBACK_SUCCESS';
export const SUBMIT_PRESENTATION_FEEDBACK_FAIL =
    'SUBMIT_PRESENTATION_FEEDBACK_FAIL';
export const SUBMIT_PRESENTATION_FEEDBACK_RESET =
    'SUBMIT_PRESENTATION_FEEDBACK_RESET';
export const SUBMIT_PRESENTATION_FEEDBACK = 'SUBMIT_PRESENTATION_FEEDBACK';

export const GET_USER_PRESENTATIONS_START = 'GET_USER_PRESENTATIONS_START';
export const GET_USER_PRESENTATIONS_SUCCESS = 'GET_USER_PRESENTATIONS_SUCCESS';
export const GET_USER_PRESENTATIONS_FAIL = 'GET_USER_PRESENTATIONS_FAIL';
export const GET_USER_PRESENTATIONS = 'GET_USER_PRESENTATIONS';
